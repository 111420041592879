import React from "react";
import Navbar from "../Components/Common/Navbar";
import research3 from "../Images/Research/2Ddata.webp";
import multi from "../Images/Research/multimodal_gif.webp";
import ar from "../Images/Research/AR.webp";
import three_d from "../Images/Research/3dTeddy.webp";
import Cards from "../Components/Research/Cards";
import "../Stylesheets/Research.css";
import research_background from "../Images/About_Us/about-background.webp";
import { TypeAnimation } from "react-type-animation";
import Footer_Trial from "../Components/Common/Footer_Trial";
import Cards_main from "../Components/Research/Cards_main";
import { Link } from "react-router-dom";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";


function Datasets() {
  return (
    <>
      <div className="min-vh-100" style={{ backgroundColor: "white" }}>
        <Navbar />
        <br />
        <br />
        <br />

        <div
          className="hero-research"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${research_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start align-items-center text-light"
              style={{
                fontWeight: 500,
                fontFamily: "Inter",
                fontSize: "5vh",
              }}
            >
              Datasets
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", 
                }}
                className="d-md-block"
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {/* <Breadcrumbs /> */}

        <div className="research-content">
          <div className="container">
            <div className="row">
              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={research3}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/clonergridmotioncopy-3133ca9854ef1ed4aff5f1c854c7b93e/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="RSUIGM"
                  id="01"
                  link="/dataset/RSUIGM"
                  desc=""
                />
              </div>

              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={three_d}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/draganddropsnapcopy-6e153bc203561a018c36a2cc113cba28/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Dataset 2"
                  id="02"
                  // link="/research/3d"
                  desc=""
                />
              </div>

              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={multi}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Dataset 3"
                  id="03"
                  // link="/research/multimodal"
                  desc=""
                />
              </div>
              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={ar}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Dataset 4"
                  id="03"
                  // link="/research/multimodal"
                  desc=""
                />
              </div>

              {/* <div className="col-md" style={{ marginInlineEnd: "1em" }}>
                <Cards
                  img={research3}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Presentation through AR / VR / XR"
                  id="03"
                  link="/research/multimodal"
                  desc="At our research center, we specialize in the exciting field of multimodal acquisition and processing."
                />
              </div> */}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer_Trial />
      </div>
    </>
  );
}

export default Datasets;
