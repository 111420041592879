import img1 from "../Images/DST-DP/dp.webp";
import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
import Year_filter from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function DST_DP() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  console.log(category)
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start text-light  align-items-center"
              style={{
                fontWeight: 500,
                // fontSize: "4vh",
                fontFamily: "Inter",
              }}
            >
              DST Sponsored: Digital Poompuhar 
            </div>
            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}s
          </div>
        </div>
        <br />
        <div className="container">
        <Breadcrumbs/>
         
          <br/>

          <div className="row">
          <div className="container">
                  {/* <p>  */}
                  3D reconstruction and visualization of the archaeological site Poompuhar demands high-quality images. Capturing high-quality images in an underwater environment encompasses several challenges due to dynamically varying underwater properties, floating particles, and submerged deposits. Approaches for the estimation of high-quality images from corrupted images may be categorized as image enhancement or image restoration.
                  {/* </p> */}
                    <ol>
                      <li>
                        Image enhancement methods aim to improve the quality of underwater images by adjusting color, and luminance and removing noise.
                      </li>
                      <li>
                        Image restoration entails reversing the effects of distortion caused by attenuation of light and back-scatter using image formation models
                      </li>
                    </ol>
                </div>  
          </div>
          <div className="row">
            <div className="col col-md text-center">
                  <img src={img1} className="img-fluid" alt="Your Image"/>
              </div>
          </div>
          <br/>
          <div className="row">
          <div className="container">
          Towards enhancement of underwater images, we have 
          <ol>
                  <li>  
                    Designed and developed a fusion-based enhancement technique for the enhancement of underwater images
                  </li>
                  <li>
                    Developed an adaptive cubic spline technique in CIE lab space to enhance underwater images.                  
                   </li>
                  <li> 
                    Emerged with a learning-based style transfer technique by encoding content and style images and apply domain transfer methodology to enhance underwater images.                  </li>
                  <li> 
                    Developed a UIENET: Underwater image enhancement network. This network 
uses a Generative Adversarial Network to learn the mapping from a degraded image to its corresponding ground truth using an image-to-image translation mechanism.
                  </li>
                </ol>
     
          Towards restoration of underwater images, we have
          <ol>
                  <li>  
                    Designed a classification framework to determine the underlying water type and perform restoration accordingly.
                  </li>
                  <li>
                    Generated synthetic underwater images with depth as a clue for modeling classification framework.
                  </li>
                  <li>
                    We trained the classification model with the help of synthetic images and test on real underwater images.
                  </li>
                  <li>
                    Developed a learning-based framework with wavelets in the pooling layer to capture the finer details for the encoder-decoder model.
                  </li>
                  <li>
                    Designed a framework for realistic underwater image generation using state-of-the-art image formation model towards training conditional generative networks for performing restoration.
                  </li>
                  <li>
                    Developed a framework for restoration of degraded underwater images using attenuation coefficients as a clue.

                   </li>
                </ol>

          Towards 3D data processing, we have 
          <ol>
                  <li>  
                    Designed a learning-based hole detection and hole filling algorithm towards refining the reconstructed 3D model.

                  </li>
                </ol>

                Towards rendering technique, in an attempt to restore the original appearance of the reconstructed 3D data, we have
          <ol>
                  <li>  
                  Rendered synthetic underwater images using state-of-the-art image formation model towards training deep learning architectures.

                  </li>
    
                </ol>

          </div>
          </div>
         {/* <div class="row">
              <div class="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div class="col-md-6">
                  <img src={img2} class="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>

               {/* related publications code */}
        <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  //  console.log("this",Paperfilter); 
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] != "Paper Title" &&
                    t[14] != null
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[14].split(", ");
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] == "DP"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
  

      </div>
      </>
  );
}

export default DST_DP;
