import React, { useEffect } from "react";
import $ from "jquery";
import "../../Stylesheets/Back_to_top.css";
import BackToTop from "../../Images/Back_to_top/back-to-top.webp";

function Back_to_top() {
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#button").hide();
    });

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        $("#button").show();
      } else {
        $("#button").hide();
      }
    }
  };

  useEffect(() => {
    jQueryCode();
  }, []);

  return (
    <>
      <div
        id="button"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <img src={BackToTop} alt="back to top" />
      </div>
    </>
  );
}

export default Back_to_top;
