import React, { useState } from "react";
import paperTypeContext from "./PaperType_context";


function PaperType(props) {
    const [paperType, setPaperType] = useState("");
  return (
    <>
      <paperTypeContext.Provider value={{paperType , setPaperType}}>
        {props.children}
      </paperTypeContext.Provider>
    </>
  );
}

export default PaperType;
