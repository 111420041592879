import React from "react";
import cevi from "../../Images/Navbar/KLExCEVI.webp";
import { Link, useLocation } from "react-router-dom";
import "../../Stylesheets/Navbar.css";
import kle_logo from "../../Images/Navbar/Kle.webp";

function Navbar_trial() {
  const location = useLocation();
  return (
    <>
      <nav
        className="navbar fixed-top navbar-expand-xxl shadow-sm"
        style={{
          backgroundColor: "white",
          fontFamily: "Inter",
          fontWeight: "500",
          color: "",
        }}
      >
        <div className="container ">
          <Link
            className="img-fluid navbar-brand rounded-2 "
            to={location.pathname === "/" ? "https://kletech.ac.in/" : "/" } 
            // target="_blank"
            // rel="noopener noreferrer" 
            style={{ 
              backgroundColor: "white",
            }}
          >
            {" "}
            {/* <img
              src={location.pathname == "/" ? kle_logo : cevi}
              className=""
              alt="CEVI"
              height="50px"
              width="100%"
            /> */}
            <img
            src={location.pathname === "/" ? kle_logo : cevi}
            className={`logo ${location.pathname === "/" ? "kle-logo" : "cevi-logo"}`}
            alt="CEVI"
            height="50px"
            width="100%"
          />
          </Link>
          
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav  gap-4">
              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/research" ||
                    location.pathname === "/research/2d" ||
                    location.pathname === "/research/3d" ||
                    location.pathname === "/research/multimodal"
                      ? "active fw-bold"
                      : ""
                  }`}
                  to="/research"
                >
                  Research
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/projects" ||
                    location.pathname ===
                      "/projects/underwater_archaeological_site" ||
                    location.pathname === "/projects/hampi_reconstruction" ||
                    location.pathname === "/projects/vaidya" ||
                    location.pathname === "/projects/shape-representation"
                      ? "active fw-bold"
                      : ""
                  }`}
                  to="/projects"
                >
                  Projects
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/publications"
                      ? "active fw-bold"
                      : ""
                  }`}
                  aria-current="page"
                  to="/publications"
                >
                  Publications
                </Link>
                {/* <a class="nav-link rounded-5 bar" href="http://localhost/cevi-website/publications.php">Publications</a> */}
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/people" ? "active fw-bold" : ""
                  }`}
                  to="/people"
                >
                  People
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/career" ? "active fw-bold" : ""
                  }`}
                  to="/career"
                >
                  Career
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/about_us" ? "active fw-bold" : ""
                  }`}
                  to="/"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/contact" ? "active fw-bold" : ""
                  }`}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link rounded-5 bar ${
                    location.pathname === "/dataset" ? "active fw-bold" : ""
                  }`}
                  to="/dataset"
                >
                  Datasets
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>
    </>
  );
}

export default Navbar_trial;
