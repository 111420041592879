// import React, { useContext } from "react";
// import yearContext from "../../Global varaibles/Year_filter/Year_context";

// function Year_filter() {
//   const years = ["2023", "2022", "2021","2020","2019"];
//   const context = useContext(yearContext);
//   const { setYear } = context;
//   return (
//     <>
//       <li>
//         {/* eslint-enable jsx-a11y/anchor-is-valid */}
//         <a
//           className="dropdown-item"
//           href="#"
//           onClick={() => {
//             setYear("");
//           }}
//         >
//           All
//         </a>
//         {/* eslint-enable jsx-a11y/anchor-is-valid */}
//       </li>

//       {years.map((e, index) => {
//         return (
//           <li key={index}>
            
//             <a
//               className="dropdown-item"
//               href="#"
//               onClick={() => {
//                 setYear(e);
//               }}
//             >
//               {e}
//             </a>
//           </li>
//         );
//       })}
//     </>
//   );
// }

// export default Year_filter;

import React, { useContext } from "react";
import yearContext from "../../Global varaibles/Year_filter/Year_context";

function YearFilter() {
  const years = ["2023", "2022", "2021", "2020", "2019"];
  const context = useContext(yearContext);
  const { setYear } = context;

  return (
    <>
      <li>
        <button
          className="dropdown-item"
          onClick={() => {
            setYear("");
          }}
        >
          All
        </button>
      </li>

      {years.map((e, index) => (
        <li key={index}>
          <button
            className="dropdown-item"
            onClick={() => {
              setYear(e);
            }}
          >
            {e}
          </button>
        </li>
      ))}
    </>
  );
}

export default YearFilter;

