// import React from "react";
import React, { useRef, useEffect  } from "react";
import Research_pages from "../Page_Layouts/Research_pages";
import hero from "../Images/Multimodal/hero.webp";
import { Link } from "react-router-dom";
// import img1 from "../Images/Multimodal/data-modelling.webp";
// import img2 from "../Images/Multimodal/compass.webp";
// import card1 from "../Images/Multimodal/card1.webp";
// import card2 from "../Images/Multimodal/card2.webp";
// import card3 from "../Images/2D/card3.webp";
// import card4 from "../Images/2D/card4.webp";
// import card6 from "../Images/2D/card6.webp";

function Multimodal() {
  return (
    <>
      <Research_pages
        hero={hero}
        main="Multimodal Data Acquisition and Processing"
        desc1="Our goal is to advance the field of multimodal acquisition and processing by pushing the boundaries of knowledge and developing novel techniques and methodologies. We collaborate with academic institutions, industry partners, and stakeholders to create meaningful impact in various domains."
        desc2="Explore our website to learn more about our ongoing research projects, publications, and collaborations. Contact us today to discuss potential partnerships, research opportunities, or to explore how our expertise in multimodal acquisition and processing can benefit your specific needs. Together, let's unlock the power of integrating multiple modalities and gain deeper insights into complex systems."
        // img1={img1}
        // img2={img2}
        cardtitle1="Sensor Fusion and Integration"
        // cardimg1={card1}
        carddesc1="We delve into the integration of data from different sensors and modalities, such as images, audio, video, depth, and physiological signals. Our research aims to develop robust techniques for fusing and integrating multimodal data to obtain a more comprehensive and accurate representation of the underlying phenomenon."
        cardtitle2="Multimodal Data Alignment and Registration"
        // cardimg2={card2}
        carddesc2="Aligning and registering multimodal data is a crucial step in multimodal analysis. We explore techniques to align and synchronize data from multiple sensors or modalities, ensuring temporal and spatial coherence. This enables meaningful analysis and extraction of complementary information from multimodal data."
        cardtitle3="Multimodal Feature Extraction"
        // cardimg3={card3}
        carddesc3="We specialize in extracting informative features from multimodal data. Our research focuses on developing algorithms and techniques to capture and represent relevant information from each modality. This enables the fusion of complementary features and facilitates multimodal analysis, classification, and recognition tasks."
        cardtitle4="Multimodal Data Fusion and Integration"
        // cardimg4={card4}
        carddesc4="We investigate methods for fusing and integrating multimodal data at various levels, including feature-level fusion, decision-level fusion, and semantic-level fusion. Our research aims to leverage the complementary nature of different modalities to enhance the understanding and interpretation of complex systems."
        cardtitle5="Multimodal Machine Learning and Deep Learning"
        // cardimg5={card5}
        carddesc5="We apply machine learning and deep learning techniques to multimodal data analysis. Our research focuses on developing algorithms and architectures that can effectively handle and leverage multimodal information for tasks such as object recognition, sentiment analysis, action recognition, and anomaly detection."
        cardtitle6="Multimodal Data Visualization and Interaction"
        // cardimg6={card6}
        carddesc6="Effective visualization and interaction methods are crucial for multimodal data analysis. We explore techniques for visualizing and presenting multimodal data in an intuitive and interpretable manner. This enables users to explore and interact with the integrated multimodal data, facilitating deeper insights and knowledge discovery."
        cardtitle7="Applications in Various Domains"
        // cardimg7={card7}
        carddesc7="Multimodal acquisition and processing find applications in diverse fields, such as healthcare, robotics, surveillance, human-computer interaction, smart environments, and multimedia analysis. We collaborate with domain experts to address specific challenges and requirements in these areas, driving innovation and creating practical solutions."
      />
    </>
  );
}

export default Multimodal;
