import ihds1 from "../Images/IHDS/ihds1.webp";
import ihds2 from "../Images/IHDS/ihds2.webp";
import ihds3 from "../Images/IHDS/ihds3.webp";
import ihds4 from "../Images/IHDS/ihds4.webp";
import ihds5 from "../Images/IHDS/ihds5.webp";
import ihds6 from "../Images/IHDS/ihds6.webp";
import ihds7 from "../Images/IHDS/ihds7.webp";
import ihds8 from "../Images/IHDS/ihds8.webp";
import ihds9 from "../Images/IHDS/ihds9.webp";
import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
import Year_filter from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function IHDS() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  console.log(category)
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="  d-flex justify-content-start text-light  align-items-center flex-wrap "
              style={{
                fontWeight: 500,
                // fontSize: "4vh",
                fontSize: "calc(1rem + 0.9vw)",
                fontFamily: "Inter",
              }}
            >
                DST Sponsored:
              <span className="truncateText">
                Crowdsourcing Framework for Digital Archiving and Presentation of Cultural Heritage
              </span>
              
              {/* DST Sponsored:
              <br/>
              Crowdsourcing Framework for Digital Archiving and Presentation of Cultural Heritage */}
            </div>

            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
       
        <br />
        <div className="container">
        <Breadcrumbs/>
          <br/>

          <div className="row ">
                  <p> 
                  The main objective of this project is to develop a crowdsourcing framework as a solution towards digital archiving and preservation of Cultural Heritage (CH) sites. Digital archiving and preservation of both tangible and intangible cultural heritage is a laborious task involving multitude of processes ranging from capturing data with various modalities and storing historical CH data. India is a nation with rich cultural history and boasts plenty of CH sites, which are essential for digital archiving and preservation to attract researchers and tourism, however, most of the data essential for the preservation is scattered, which makes digital archiving and preservation an insurmountable task. Towards this we propose to build a crowdsourcing framework which guides or assists "the crowd" in acquiring and uploading the historical data by providing a crowdsourcing platform and (semi-) automatically processing the data for preserving, reconstruction and recreation of the archived data as well as to develop demonstrable content of the CH sites.                   </p>
          </div>
          <div className="row">
              <div className="col col-md text-center">
                  <img src={ihds1} className="img-fluid" alt="Your Image"/>
                </div>
          </div>
          <br/>
          <br/>
          <div className="row">
          <div className="container">
          Towards this we develop the following: 
          <ol>
                  <li>  
                  To set up a web-based crowdsourcing platform to assist users to acquire and upload data: Designed and developed a crowdsourcing platform capable of assisting users to upload the acquired data towards archival. 
                  </li>
                  <li>
                  To develop a solution for data clean-up, filtering and structuring of crowdsourced data: To make the data suitable for 3D reconstruction and presentation, we propose number of methods to refine the data like removal of shadow, occlusions, blur, removal of images taken from similar camera positions, categorization of images for a particular heritage site, selection images for 3D reconstruction etc.                    
                   </li>
                  <li> 
                  To set up a 3D reconstruction pipeline as an efficient operational procedure to easily replicate generating demonstrable content for the CH sites from the acquired data with minimal training. Propose algorithms for refinement of generated 3D models (Point cloud representations) with number of 3D data processing methods like hole detection, hole filling and super resolution of generated point cloud.
                  </li>
                  <li> 
                  To demonstrate digital reconstruction/recreation of heritage through 3D reconstruction from crowdsourced data: We demonstrate the 3D reconstructions of cultural heritage through AR and VR.
                  </li>
                  {/* <li>
              To demonstrate visual presentation of point cloud data by storytelling and virtual reality.
                   </li> */}
                </ol>
     

          </div>
          </div>
         {/* <div class="row">
              <div class="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div class="col-md-6">
                  <img src={img2} class="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>

               {/* related publications code */}
        <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  //  console.log("this",Paperfilter); 
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] !== "Paper Title" &&
                    t[14] !== null
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[14].split(", ");
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] == "IHDS"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
  

      </div>
      </>
  );
}

export default IHDS;
