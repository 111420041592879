import rps1 from "../Images/AICTE_RPS/rps1.webp";
import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
import pub_background from "../Images/Publications/pub_background.webp";
import Year_filter from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function AICTE_RPS() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  console.log(category)
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            {/* <div
              className="d-flex justify-content-start text-light  align-items-center flex-wrap"
              style={{
                fontWeight: 500,
                // fontSize: "4vh",
                fontSize: "calc(1rem + 0.9vw)",
                fontFamily: "Inter",
              }}
            >
              <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              AICTE-Research Promotion Scheme (RPS): 
              </span>
              <br/>
              <span style={{ display: "block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              Shape Representation, Reconstruction and Rendering of 3D Models
              </span>
            </div> */}


<div
              className="d-flex justify-content-start text-light  align-items-center flex-wrap"
              style={{
                fontWeight: 500,
                // fontSize: "4vh",
                fontSize: "calc(1rem + 0.9vw)",
                fontFamily: "Inter",
              }}
            >
              AICTE-Research Promotion Scheme (RPS): 
              <br/>
              <span className="truncateText">
              Shape Representation, Reconstruction and Rendering of 3D Models
              </span>
            </div>



            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
        
        <br />
        <div className="container">
        <Breadcrumbs/>
         
          <br/>

          <div className="row">
                  <p> 
                  The 3D data acquired using scanning devices have one of the challenging issue i.e., missing regions or holes due to scanning devices or damaged models. The acquired point cloud data exhibit non-uniform distribution of geometry and hence we propose a set of 3D shape representation methods that will capture the inherent geometry of the acquired 3D data. We have proposed two approaches, namely, (A) rigid and non-rigid geometry-based conventional feature descriptors using metric tensor and Christoffel symbols, and wave kernel signature and (B) convolution architecture for decomposition of point clouds in primitive shapes. Next, in order to address the issues of missing regions or damaged models, we propose efficient data processing algorithms. This is a two-fold problem, namely, (A) hole detection where a learning-based approach is proposed for automatic detection of hole boundaries and (B) inpainting the missing regions under progress of complex 3D models. These are then considered for building 3D reconstruction pipeline focusing on shape completion. Towards this, we focus on a coarse-to-fine 3D reconstruction pipeline for shape completion of point cloud data. We propose two methods to perform (A) edge-aware up-sampling on sparse point-clouds, (B) up-sampling of point cloud and its associated features, and (C) Densification of depth using RGB and sparse point cloud towards the refinement of structural details in 3D models. The refined model needs to be rendered and presented using VR which is in progress.
                  </p>
          </div>
          <div className="row">
                <div className="col col-md text-center">
                  <img src={rps1} className="img-fluid" alt="Your Image"/>
                </div>
          </div>
          <br/>

          <div className="row">
          <div className="container">
          Objectives of the Project:
          <ol>
                  <li>  
              To propose geometry-based features for 3D shape representation, which capture the inherent geometry of the point cloud data.

                  </li>
                  <li>
              To develop exemplar-based 3D inpainting algorithms to in-paint the missing regions of the point cloud data.
                  
                   </li>
                  <li> 
              To develop a coarse-to-fine 3D reconstruction framework for shape completion of point cloud data.
                  </li>
                  <li> 
              To develop a point-based rendering technique in an attempt to restore the original appearance of reconstructed point cloud data.
                  </li>
                  <li>
              To demonstrate visual presentation of point cloud data by storytelling and virtual reality.
                   </li>
                </ol>
     

          </div>
          </div>
        </div>

               {/* related publications code */}
        <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                marginTop: "1em",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  //  console.log("this",Paperfilter); 
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] !== "Paper Title" &&
                    t[14] !== null
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[14].split(", ");
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] === "RPS"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      // fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
  

      </div>
      </>
  );
}

export default AICTE_RPS;
