import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
// import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import img1 from "../Images/2D/IQA.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
// import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
// import pub_background from "../Images/Publications/pub_background.webp";
// import Year_filter from "../Components/Publications/Year_filter";
// import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
// import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Two_Dimensional_Page5() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> 
        <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start text-light  align-items-center"
              style={{
                fontWeight: 500,
                // fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
          Quality Analysis of Images
            </div>
            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
        <br />
        <div className="container">
        <Breadcrumbs/>
          <div className="row">
          <div className="container">
          

                  A fundamental pillar of our research is the assurance of high quality and dependable acquired images. To achieve this, we invest significant efforts in the development of innovative methods and metrics specifically designed for assessing image quality. These metrics encompass critical factors such as sharpness, color fidelity, and noise levels. Through meticulous quality analysis, we not only quantify the excellence of our acquired images but also pinpoint potential issues within the image acquisition process. In this pursuit, we harness advanced tools and software, such as MATLAB imatest, in conjunction with a variety of image calibration charts. These sophisticated tools empower us to perform precise and comprehensive image quality assessments, ensuring that the images meet the stringent standards required for a wide array of applications, including but not limited to digital heritage preservation, medical imaging, and computer vision.
            </div>
          </div>
          <br/>

          <div className="row">
              <div className="col text-center">
                  <img src={img1} className="img-fluid" alt="..." />
                </div>
          </div>
        </div>
                {/* related publications code */}
                <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] !== "Paper Title"
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[9].split(", ");
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] === "Quality Analysis of Images"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
      </div>
      </>
  );
}

export default Two_Dimensional_Page5;
