import React from "react";
import CarouselItem from "./Carousel_Item";
import faculty5 from "../../Images/People/Team Members/Sindhu.webp";
import faculty2 from "../../Images/People/Team Members/deepti.webp";
import faculty3 from "../../Images/People/Team Members/Rohan.webp";


function Carousel() {
  return (
    <>
      <div id="carouselExample" className="carousel carousel-dark slide ">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <CarouselItem
              img={faculty5}
              testimonial="Working with the Visual Intelligence Lab was a transformative experience for me. Their cutting-edge technologies and innovative approaches to image analysis opened up new avenues of creativity in my digital art. The insights and tools they provided have elevated the quality and depth of my work, allowing me to push the boundaries of visual storytelling." 
            />
          </div>
          <div className="carousel-item ">
            <CarouselItem
              img={faculty2}
              testimonial="Partnering with the Visual Intelligence Lab gave our marketing campaigns a competitive edge. Their expertise in image recognition and analysis enabled us to understand our audience's preferences on a deeper level. This led to more targeted and effective visual content, resulting in increased engagement and conversions. The lab's insights are now an integral part of our marketing strategy."
            />
          </div>
          <div className="carousel-item">
            <CarouselItem
              img={faculty3}
              testimonial="As a researcher in the field of computer vision, collaborating with the Visual Intelligence Lab was invaluable. Their dedication to pushing the boundaries of image understanding is evident in their advanced algorithms and tools. The lab's resources and expertise have accelerated our progress in solving complex problems and advancing the state of the art in visual intelligence."
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default Carousel;
