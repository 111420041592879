import React from "react";
import { Routes, Route, useLocation, useNavigate  } from "react-router-dom";
import { BrowserRouter} from 'react-router-dom';
import { Link } from "react-router-dom";

import Footer from "../Components/Common/Footer_Trial";
import Navbar from "../Components/Common/Navbar";
import TopPageScroll from "../Components/Common/TopPageScroll";
import About_Us from "../Pages/About_Us";
import Career from "../Pages/Career";
import Home from "../Pages/Home";
import People from "../Pages/People";
import Projects from "../Pages/Projects";
import Publications from "../Pages/Publications";
import Research from "../Pages/Research";
import SEED from "../Pages/SEED";
import Research_pages from "../Page_Layouts/Research_pages";
import Two_Dimensional from "../Pages/Two_Dimensional";
import Three_dimentional from "../Pages/Three_dimentional";
import Multimodal from "../Pages/Multimodal";
import About_Trial from "../Pages/About_Trial";
import Seed_Trial from "../Pages/Seed_Trial";
import Contact_Trial from "../Pages/Contact_Trial";
import Animation from "../Pages/Animation";
import Publication_pages from "../Page_Layouts/Publication_pages";
import Projects_page from "../Page_Layouts/Projects_page";
import Sponsored_Research from "../Pages/Sponsored_Research";
import Consultancy from "../Pages/Consultancy";
import AICTE_RPS from "../Pages/AICTE_RPS";
import IHDS from "../Pages/IHDS";
import Vaidya from "../Pages/Vaidya";
import DST_DP from "../Pages/DST_DP";
import Two_Dimensional_Page1 from "../Pages/Two_Dimensional_Page1";
import Two_Dimensional_Page2 from "../Pages/Two_Dimensional_Page2";
import Two_Dimensional_Page3 from "../Pages/Two_Dimensional_Page3";
import Two_Dimensional_Page4 from "../Pages/Two_Dimensional_Page4";
import Two_Dimensional_Page5 from "../Pages/Two_Dimensional_Page5";
import Two_Dimensional_Page6 from "../Pages/Two_Dimensional_Page6";
import Three_Dimensional_Page1 from "../Pages/Three_Dimensional_Page1";
import Three_Dimensional_Page2 from "../Pages/Three_Dimensional_Page2";
import Three_Dimensional_Page3 from "../Pages/Three_Dimensional_Page3";
import Three_Dimensional_Page4 from "../Pages/Three_Dimensional_Page4";
import Three_Dimensional_Page5 from "../Pages/Three_Dimensional_Page5";
import Three_Dimensional_Page6 from "../Pages/Three_Dimensional_Page6";
import Datasets from "../Pages/Datasets";
import Dataset_RSUIGM from "../Pages/Dataset_RSUIGM";

// import Test from "../Pages/test";

// import { Link } from "react-router-dom";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";


function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  // const currentPath = location.pathname;

  return (
    <>
      <TopPageScroll />
      {/* <Breadcrumbs />  */}
      <Routes>
        {/* <Route exact path="/about_us" element={<About_Trial />} />
        <Route exact path="/career" element={<Career />} /> */}
        <Route exact path="/contact" element={<Contact_Trial />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/People" element={<People />} />
        <Route exact path="/Projects" element={<Projects />} />
        <Route exact path="/Publications" element={<Publications />} />
        <Route exact path="/Research" element={<Research />} />
        <Route path="/seed" element={<Seed_Trial />} />
        <Route path="/research/2d" element={<Two_Dimensional />} />
        <Route path="/research/3d" element={<Three_dimentional />} />
        <Route path="/research/multimodal" element={<Multimodal />} />
        <Route path="/projects/Sponsored" element={<Sponsored_Research />} />
        <Route path="/projects/Consultancy" element={<Consultancy />} />
        <Route path="/projects/sponsored/aicte_rps" element={<AICTE_RPS />} />
        <Route path="/projects/sponsored/ihds" element={<IHDS />} />
        <Route path="/projects/sponsored/vaidya" element={<Vaidya />} />
        <Route path="/projects/sponsored/dst-dp" element={<DST_DP />} />
        <Route path="/projects/sponsored/dst-dp" element={<DST_DP />} />
        <Route path="/research/2d/Two_Dimensional_Page1" element={<Two_Dimensional_Page1/>} />
        <Route path="/research/2d/Two_Dimensional_Page2" element={<Two_Dimensional_Page2/>} />
        <Route path="/research/2d/Two_Dimensional_Page3" element={<Two_Dimensional_Page3/>} />
        <Route path="/research/2d/Two_Dimensional_Page4" element={<Two_Dimensional_Page4/>} />
        <Route path="/research/2d/Two_Dimensional_Page5" element={<Two_Dimensional_Page5/>} />
        <Route path="/research/2d/Two_Dimensional_Page6" element={<Two_Dimensional_Page6/>} />
        <Route path="/research/3d/Three_Dimensional_Page1" element={<Three_Dimensional_Page1/>} />
        <Route path="/research/3d/Three_Dimensional_Page2" element={<Three_Dimensional_Page2/>} />
        <Route path="/research/3d/Three_Dimensional_Page3" element={<Three_Dimensional_Page3/>} />
        <Route path="/research/3d/Three_Dimensional_Page4" element={<Three_Dimensional_Page4/>} />
        <Route path="/research/3d/Three_Dimensional_Page5" element={<Three_Dimensional_Page5/>} />
        <Route path="/research/3d/Three_Dimensional_Page6" element={<Three_Dimensional_Page6/>} />
        <Route path="/dataset" element={<Datasets/>} />
        <Route path="/dataset/RSUIGM" element={<Dataset_RSUIGM />} />


        {/* <Route path="/test" element={<Test />} /> */}
        <Route
            path="*"
            element={<navigate to="/" />}
          />
      </Routes>
    </>
  );
}

export default Router;
