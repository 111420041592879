// import React, { useContext, useEffect, useState, useRef } from "react";
import React from "react";
import NETS from "vanta/dist/vanta.net.min";
import placeholder from "../Images/About_Us/placeholder.webp";

// import about3 from "../Images/About_Us/about3.webp";
import "../Stylesheets/About_Us.css";
import BackToTop from "../Components/Common/BackToTop";
import Footer from "../Components/Common/Footer_Trial";
import Navbar from "../Components/Common/Navbar";
import about_background from "../Images/About_Us/about-background.webp";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";

function About_Us() {
  return (
    <>
      <div
        className="about-background main d-flex flex-column min-vh-100"
        style={{
          fontFamily: "Inter",
        }}
      >
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />

        <div
          className=""
          id=""
          style={{
            minHeight: "40vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "15vh" }}></div>
            <div
              className="d-flex justify-content-start text-light  align-items-center display-3"
              style={{
                fontWeight: 900,
                marginLeft: "10vw",
                fontFamily: "DM Sans",
                letterSpacing: "1.4px",
              }}
            >
              About Us
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Poppins",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <br />
          <div className="row">
            <br />
            <div className="col-md-6 ">
              <p
                className="display-6 mb-0 fw-normal text-body-secondary"
                style={{ fontFamily: "Times New Roman, Times " }}
              >
                Center of Excellence in
              </p>
              <p
                className="display-3 mb-0 fw-normal"
                style={{ fontFamily: "Times New Roman, Times " }}
              >
                Visual Intelligence
              </p>{" "}
              <br />
            </div>
            <div className="col-md-6 fs-5" style={{ fontFamily: "" }}>
              <p>
                Center of Excellence in Visual Intelligence (CEVI) earlier known
                as Computer Vision and Graphics (CVG) lab is a research center
                with the focus towards creating a renowned, state-of-the-art
                facility equipped to create innovative visual intelligence
                solutions. The CEVI has research footprints in the area of
                artificial intelligence and data engineering through its
                collaborations, funded projects since 2009. CEVI conducts summer
                and winter workshops (Visual intelligence workshop) every year,
                catering 500 plus students and faculty across institutes. The
                faculty and students at CEVI have published papers in renowned
                journals and conferences like TPAMI, MVA, CGJ, JNN, CVPR, ICCV,
                SIGGRAPH, SIGGRAPH ASIA, NeurIPS, ICPR, ICDAR, ACCV, BMVC.{" "}
              </p>

              <br />
              <br />
            </div>
            <hr />
          </div>
          <br />
          <br />
          <br />
          <div className="row d-flex justify-content-center">
            <img
              // src={about1}
              className="img-fluid"
              style={{ width: "80vw" }}
              alt="..."
            />
          </div>
          <br />
          <br />
          <div className="container">
            <hr />
          </div>
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col-md-6 align-self-center">
              <h3>Vision</h3>
              <p className="fs-5">
                Our focus is to create a renowned, state-of-the-art facility
                equipped to create innovative visual intelligence solutions.
                <p>
                  <br />
                  CEVI aims at strengthening industry-academia partnerships, by
                  building capabilities among students, faculty, and researchers
                  to work on projects related to thrust areas like artificial
                  intelligence and data engineering.
                </p>
              </p>
            </div>
            <div className="col-md-6">
              <img
                // src={about2}
                className="img-fluid"
                style={{ width: "50vw" }}
                alt="..."
              />
            </div>
          </div>
          <br />
          <div>
            <br />
            <hr />
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-6">
              <img
                // src={about3}
                className="img-fluid"
                style={{ width: "50vw" }}
                alt="..."
              />
            </div>
            <div className="mt-4 col-md-6 align-self-center">
              <h3>Mission</h3>
              <p className="fs-5">
                To be pioneers in visual intelligence by providing innovative
                environment, capture capabilities, outstanding research
                experience, global recognition, and research excellence with an
                impact on the industry and society.
              </p>
            </div>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <div className="row">
            <div className="container frame col-md-6">
              <iframe
                className="iframe rounded-5"
                src="https://my.spline.design/untitled-8dd9b91a3de524e6b401887956873f96/"
                frameBorder="0"
                style={{
                  border: "0",
                  height: "485px",
                  width: "100%",
                }}
              ></iframe>
              <br />
              <br />
              <div className="d-flex justify-content-center">
                <i>Model.</i> &nbsp;&nbsp; <b> Green Room (SEED Facility)</b>
              </div>
            </div>

            <div className="col-md-6">
              <iframe
                className="iframe rounded-5"
                src="https://my.spline.design/darkroom-14af5d0dd136641892e66e0f63782c6e/"
                frameBorder="0"
                style={{ border: "0", height: "485px", width: "100%" }}
              ></iframe>
              <br />
              <br />
              <div className="d-flex justify-content-center">
                <i>Model.</i> &nbsp;&nbsp; <b> Dark Room (SEED Facility)</b>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default About_Us;
