import React from "react";
import "../Stylesheets/Home.css";
import Navbar from "../Components/Common/Navbar";
import research3 from "../Images/Home/2Ddata.webp";
import Cards from "../Components/Research/Cards";
import Cardsp from "../Components/Projects/Cards";
import eye from "../Images/Home/CEVI.webp";
import FooterTrial from "../Components/Common/Footer_Trial";
import { Link } from "react-router-dom";
import Horizontal_Card from "../Components/Home/Horizontal_Card";
import PublicationsImage1 from "../publication_thumbnails/2023_Oct_1.webp"
import PublicationsImage2 from "../publication_thumbnails/2023_Oct_3.webp"
import PublicationsImage3 from "../publication_thumbnails/2023_Oct_4.webp"


import about from "../Images/Home/Achievements.webp";
import Carousel from "../Components/Home/Carousel";
import collab6 from "../Images/About_Us/docketrun.webp";
import collab1 from "../Images/About_Us/iiit.webp";
import collab2 from "../Images/About_Us/samsung.webp";
import collab3 from "../Images/About_Us/collab3.webp";
import collab4 from "../Images/About_Us/collab2.webp";
import collab5 from "../Images/About_Us/collab5.webp";
import gif1 from "../Images/Home/gif1.webp";
import gif2 from "../Images/Home/gif2.webp";
import gif3 from "../Images/Home/gif3.webp";
import gif4 from "../Images/Home/gif4.webp";
import gif5 from "../Images/Home/gif5.webp";
import gif6 from "../Images/Home/hampi.webp";
import gif9 from "../Images/Home/underwater.webp";
import gif10 from "../Images/Home/gif10.webp";
import gif11 from "../Images/Home/gif11.webp";
import mutli from "../Images/Home/multimodal_gif.webp";
import three_d from "../Images/Home/3dTeddy.webp";
import sponsored from "../Images/Home/Sponsored.webp";
import Collaborations from "../Images/Home/Collabrative.webp";
import Consultancy from "../Images/Home/Consultancy.webp";
import Marquee from "../Components/Home/Marquee";

// import Spline from '@splinetool/react-spline';



function Home_trial() {  

  return (
    <>
      <div className="" style={{ fontFamily: "Inter" }}>
        <Navbar />
        
        {/* <div
          style={{ paddingBlockStart: "3em", textAlign: "right"}}
          className="section12 "
        >
          <p><Link to="/">Home</Link> </p>
        </div> */}

        <section
          className="section1 min-vh-100"
          style={{ paddingBlockStart: "8em" }}
        >
          <div className="container">
            <div className="row gy-5">
              <div className="col-sm ">
                <div className="img d-flex ms-0 justify-content-center align-items-center" >
                  <img
                    src={eye}
                    style={{ width: "50%" , height: "auto" }}
                    className="img-fluid" 
                    alt="Cevi"
                  /> 

              {/* <iframe src='https://my.spline.design/molang3dcopy-6bc067bee7f47b3e4a410db1c73f3ea7/' frameborder='0' width='100%' height='100%'></iframe> */}
                  {/* <canvas style={{ display: "block", position: "fixed"}} id="canvas3d" height="667"></canvas> */}
                  {/* <canvas  id="canvas3d" height="667"></canvas> */}
                  {/* <Spline scene="https://prod.spline.design/Bv0wC-qGJ0J0a-dI/scene.splinecode"
                  
                   style={{
                    style={{ position: "fixed", top: -125, left:  -356 }}
                    width: "100%",
                    height: "100%",
                  }}  /> */}
                </div>
              </div>
              
              <div className="col-sm align-self-center">
                <div
                  className="title "
                  style={{ fontWeight: "500", lineHeight: "1" }}
                > 
                  <p className="" style={{ fontSize: "calc(0.9rem + 0.5vw)" }}>
                  {/* <p style={{ fontsize: 'clamp(2.5rem, 5vw, 4rem)'}}> */}
                    Center of Excellence in
                    <br />
                    <span className="" style={{ fontSize: "calc(1.525rem + 0.9vw)"}}>Visual Intelligence</span>
                    {/* <span style={{ fontSize: "5vh"}}>Visual Intelligence</span> */}

                  </p>
                </div>
                <div className="sub-title" style={{ lineHeight: "1.7" }}>
                  To be pioneers in visual intelligence by providing innovative
                  environment, capture capabilities, outstanding research
                  experience, global recognition, and research excellence with
                  an impact on the industry and society.
                </div>
                {/* <Link
                  // to="/about_us"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <div className="btn rounded-0" style={{ marginBlockStart: "1em" }}> */}
                  {/* <button className="card-button">Know More</button> */}
                  {/* </div> */}
                  {/* <div className="col-lg-3 d-flex justify-content-center align-items-center">
            
          </div> */}
                {/* </Link> */}
              </div>
            </div>
            <div style={{ paddingBlock: "1em", marginBlockStart: "2rem" }}>
              <Marquee
                imgs={[
                  {
                    img: `${gif1}`,
                    text: "internship",
                  },
                  {
                    img: `${gif2}`,
                    text: "3d room",
                  },
                  {
                    img: `${gif6}`,
                    text: "hampi",
                  },
                  {
                    img: `${gif9}`,
                    text: "underwater",
                  },
                  {
                    img: `${gif5}`,
                    text: "annotation",
                  },
                  {
                    img: `${gif3}`,
                    text: "annotation",
                  },
                  // OLD
                  // {
                  //   img: `${gif7}`,
                  //   text: "trial1",
                  // },
                  // {
                  //   img: `${gif8}`,
                  //   text: "trial1",
                  // },
                  {
                    img: `${gif4}`,
                    text: "annotation",
                  },
                  {
                    img: `${gif10}`,
                    text: "dark room",
                  },
                  {
                    img: `${gif11}`,
                    text: "capture setup",
                  },
                  // {
                  //   img: `${gif12}`,
                  //   text: "trial10",
                  // },
                ]}
              />
            </div>
            {/* <div className="images" style={{ paddingBlockStart: "3.5em" }}>
              <div className="row">
                <div className="col-sm g-0">
                  <img
                    src={gif1}
                    style={{ height: "100%", width: "100%" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-sm g-0">
                  <img
                    src={gif2}
                    style={{ height: "100%", width: "100%" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-sm g-0">
                  <img
                    src={gif3}
                    style={{ height: "100%", width: "100%" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-sm g-0">
                  <img
                    src={gif4}
                    style={{ height: "100%", width: "100%" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div> */}

            <div className="" style={{ paddingBlock: "3em" }}>
              <div className="title pb-4"
                style={{
                  // color: "#05445E",
                  // fontSize: "3vh",
                  // paddingBlock: "1em",
                  // fontWeight: "600",
                  // textAlign: "center"
                  textAlign: "center",
                  // fontSize: "5vh",
                  fontWeight: "400",
                  paddingBlockEnd: "1em",
                  fontFamily: "Inter",
                  color: "#05445E",
                }}
              >
                Collaborations with Institutions
              </div>

              <div className="row gy-5" style={{ paddingBlockEnd: "3em" }}>
                <div className="col-sm col-6 d-flex align-items-center justify-content-center">
                  <Link to="https://www.iiit.ac.in/" target="_blank" rel="noopener noreferrer">
                    <img src={collab1} alt="iiit" className="img-fluid" />
                  </Link>
                </div>

                <div className="col-sm-2 col-6  d-flex align-items-center justify-content-center">
                  <Link
                    to="https://docketrun.com/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={collab6} alt="docketrun" />
                  </Link>
                </div>
                <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
                  <Link to="https://home.iitd.ac.in/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={collab3} alt="iitd" />
                  </Link>
                </div>
                <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
                  <Link to="https://kledental-bgm.edu.in/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={collab4} alt="kledental" />
                  </Link>
                </div>
                <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
                  <Link to="https://www.aiimsexams.ac.in/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={collab5} alt="aiims" />
                  </Link>
                </div>
                <div className="col-sm-2 col-6 d-flex align-items-center justify-content-center">
                  <Link
                  // to="collaborations_6" target="_blank" rel="noopener noreferrer"
                  >
                    <img className="img-fluid" src={collab2} alt="collab2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* <section
          className="section2 text-dark"
          style={{ paddingBlock: "5em", backgroundColor: "#D4F1F4" }}
        >
          <div style={{}}>
            <div
              className="title"
              style={{
                textAlign: "center",
                fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Research Focus
            </div>
            <div className="container">
              <div className="row gy-5">
                <div className="col-md">
                  <Cards
                    img={sponsored}
                    title="Sponsored Research Project"
                    id="01"
                    link="/research/2d"
                    desc=""
                  />
                </div>

                <div className="col-md">
                  <Cards
                    img={sponsored}
                    title="Collaborative Research Project"
                    id="02"
                    link="/research/3d"
                    desc=""
                  />
                </div>

                <div className="col-md">
                  <Cards
                    img={sponsored}
                    title="Consultancy Project"
                    id="03"
                    // link="/research/multimodal"
                    desc=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="section2 text-dark"
          style={{ paddingBottom: "3em",backgroundColor: "" }}
        >
          <div style={{}}>
            <div
              className="title"
              style={{
                
                textAlign: "center",
                // fontSize: "5vh",
                fontWeight: "400",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                
              }}
            >
              Research Focus
            </div>
            <div className="container">
              <div className="row gy-5">
                <div className="col-lg col-md-6 gx-md-3 gx-0">
                  <Cardsp
                    img={research3}
                    title="2D Data Acquisition and Processing"
                    id="01"
                    link="/research/2d"
                    desc="Our research extensively harnesses the capabilities of deep generative models, not only for image enhancement and denoising but also for data augmentation, thereby significantly refining the interpretability and quality of images captured in low-light conditions. "
                    
                  />
                </div>

                <div className="col-lg col-md-6 gx-md-3 gx-0">
                  <Cardsp
                    img={three_d}
                    title="3D Data Acquisition and Processing"
                    id="02"
                    link="/research/3d"
                    desc="Our current research focus is on 3D data acquisition and processing is dedicated to enhancing machines' ability to perceive, analyze, and interact with the 3D world. Ultimately, our aim is to empower machines with a deeper understanding of the 3D visual data they encounter."
                  />
                </div>

                <div className="col-lg  mx-auto col-md-6 gx-md-3 gx-0">
                  <Cardsp
                    img={mutli}
                    title="Multimodal Data Acquisition and Processing"
                    id="03"
                    // link="/research/multimodal"
                    desc="This research aims to develop AI models that can simultaneously process and comprehend visual and textual information, enabling machines to glean richer insights from multimedia content. Applications span a wide spectrum, where the synergy of vision and text enhances data interpretation and decision-making."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section2 text-dark"
          // style={{ paddingBlock: "3em", backgroundColor: "" }}
          style={{ paddingBlock: "5em", backgroundColor: "#D4F1F4" }}
        >
          <div style={{}}>
            <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                fontWeight: "400",
              }}
            >
              Research Projects
            </div>
            <div className="container">
              <div className="row  gy-5">
                <div className="col-lg col-md-6 ">
                  <Cards
                    img={sponsored}
                    title="Sponsored Research Project"
                    id="01"
                    link="/projects/sponsored"
                    // desc="At our research center, we specialize in the exciting field of 2D data acquisition and processing. At our research center, we specialize in the exciting field of 2D data acquisition and processing."
                  />
                </div>

                <div className="col-lg col-md-6 ">
                  <Cards
                    title="Collaborative Research Project"
                    img={Collaborations}
                    id="02"
                    // link="/research/3d"
                    // desc="At our research center, we specialize in the fascinating field of 3D data acquisition and processing. At our research center, we specialize in the fascinating field of 3D data acquisition and processing."
                  />
                </div>

                <div className="col-lg mx-auto col-md-6">
                  <Cards
                    img={Consultancy}
                    title="Consultancy Project"
                    id="03"
                    // link="/research/multimodal"
                    // desc="At our research center, we specialize in the exciting field of multimodal acquisition and processing. At our research center, we specialize in the exciting field of multimodal acquisition and processing."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <br/>
        <section
          className="section2 text-dark"
          style={{ paddingBlock: "3em", backgroundColor: "" }}
          // style={{  backgroundColor: "" }}
        >
          <div style={{}}>
            <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                fontWeight: "400",
              }}
            >
              Recent Publications
            </div>

            <div className="container">
              <div className="">
                <Horizontal_Card
                  title="LightNet: Generative Model for Enhancement of Low-Light Images"
                  author="Chaitra Desai, Nikhil Akalwadi, Amogh Joshi, Sampada Malagi, Chinmayee Mandi, Ramesh Ashok Tabib, Ujwala Patil, Uma Mudenagudi"
                  time="WiCV | ICCVW, Oct - 2023"
                  imageSrc={PublicationsImage1} 
                  pdfLink="https://openaccess.thecvf.com/content/ICCV2023W/WiCV/papers/Desai_LightNet_Generative_Model_for_Enhancement_of_Low-Light_Images_ICCVW_2023_paper.pdf"
                  codeLink="null"
                  videoLink="null"
                />
                <Horizontal_Card
                  title="DeFi: Detection and Filling of Holes in Point Clouds Towards Restoration of Digitized Cultural Heritage Models"
                  author="Ramesh Ashok Tabib, Dikshit Hegde, Tejas Anvekar, Uma Mudenagudi"
                  time="e-Heritage | ICCVW, Oct - 2023"
                  imageSrc={PublicationsImage2}
                  pdfLink="https://openaccess.thecvf.com/content/ICCV2023W/e-Heritage/papers/Tabib_DeFi_Detection_and_Filling_of_Holes_in_Point_Clouds_Towards_ICCVW_2023_paper.pdf"
                  codeLink="null"
                  videoLink="null"
                />
                <Horizontal_Card
                  title="ASUR3D: Arbitrary Scale Upsampling and Refinement of 3D Point Clouds using Local Occupancy Fields"
                  author="Akash Kumbar, Tejas Anvekar, Ramesh Ashok Tabib, Uma Mudenagudi"
                  time="e-Heritage | ICCVW, Oct - 2023"
                  imageSrc={PublicationsImage3}
                  pdfLink="https://openaccess.thecvf.com/content/ICCV2023W/e-Heritage/html/Kumbar_ASUR3D_Arbitrary_Scale_Upsampling_and_Refinement_of_3D_Point_Clouds_ICCVW_2023_paper.html"
                  codeLink="null"
                  videoLink="null"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section3" style={{ paddingBlock: "3em"}}>
          <div className="container">
          <div className="title"
                  style={{
                    textAlign: "center",
                    // fontSize: "5vh",
                    color: "#05445E",
                    fontWeight: "400",
                  }}
                >
                  Achievements
                </div>
            <div className="row" style={{ paddingBlock: "3em"}}>
              <div className="col-md">
              
                <div style={{ paddingBlockEnd: "0.5em", lineHeight: "2" }}>
                  {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Explicabo, ab sint! Consequatur tempore modi minus dicta illum
                  deserunt et adipisci culpa, aliquid ipsum! Iusto, voluptatibus
                  aspernatur! Aspernatur sed repellat illum. */}
                </div>
                <div className="row flex-wrap">
                  <div className="col ">
                    <div style={{ padding: "0.5em", paddingInlineStart: "0" }}>
                      <div className= "" style={{ color: "#05445E" , fontSize: "3vh" }}>
                        1000+
                      </div>
                      <div className= "">Students Trained</div>
                    </div>

                    <div style={{ padding: "0.5em", paddingInlineStart: "0" , paddingBottom:"2.5em"}}>
                      <div className= "" style={{ color: "#05445E" , fontSize: "3vh" }}>
                        13+
                      </div>
                      <div className= "">Funded Projects (IHDS, DP & more)</div>
                    </div>
                  </div>
                  {/* <div className="col-md-6"> */}
                  <div className="col">
                    <div style={{ padding: "0.5em", paddingInlineStart: "0" }}>
                      <div className= "" style={{ color: "#05445E" ,  fontSize: "3vh" }}>
                        100+
                      </div>
                      <div className= "">Publications</div>
                    </div>
                    <div style={{ padding: "0.5em", paddingInlineStart: "0" }}>
                      <div className= "" style={{ color: "#05445E" ,  fontSize: "3vh"}}>
                        5+
                      </div>
                      <div className= "">Startups</div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg align-items-center">
                <img className="img-fluid" src={about} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <br/>

        

          {/* <div className="container">
            <div
              style={{
                textAlign: "center",
                color: "#05445E",
                fontSize: "3vh",
                paddingBlockEnd: "1em",
              }}
            >
              Collaborators
            </div>
            <div className="row gy-5" style={{ paddingBlockEnd: "2em" }}>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img src={collab1} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src={collab2} alt="" />
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src={collab3} alt="" />
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src={collab4} alt="" />
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src={collab5} alt="" />
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src={collab6} alt="" />
              </div>
            </div>
          </div> */}
        <section
          className="section4 d-none d-lg-block"
          style={{ backgroundColor: "#D4F1F4", paddingBlock: "3em" }}
        >
          <div className="container">
            <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                color: "#05445E",
                fontWeight: "400",
                marginBlockEnd: "1em",
              }}
            >
              Testimonials
            </div>
            <Carousel />
          </div>
        </section>
        <script type="module">
            {/* const canvas = document.getElementById("canvas3d");
            const app = new Application(canvas);
            app.load("./scene.splinecode"); */}

            

          </script>

        <FooterTrial />
      </div>
    
    </>
  );
}

export default Home_trial;
