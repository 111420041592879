import React, { useContext, useEffect , useState} from "react";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop  from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
// import placeholder from "../publication_thumbnails/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import FooterTrial  from "../Components/Common/Footer_Trial";
import pub_background from "../Images/Publications/pub_background.webp";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import YearFilter  from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";

// import Breadcrumbs from "../Components/Common/Breadcrumbs";


function Publications() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  // let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  // const loadExcel = async () => {
  //   // const response = await fetch("http://10.2.0.79:3001/api/excel-data");
  //   // const response = await fetch("http://localhost/cevi-website/publication_react.php");
  //   const response = await fetch("https://cevi.co.in/publication_react.php");
  //   const json = await response.json();

  //   json.shift();
  //   setData(json);
  // };
  const loadExcel = async () => {
  try{
    // const response = await fetch("http://10.2.0.79:3001/api/excel-data");
    // const response = await fetch("http://localhost/cevi-website/publication_react.php");
    const response = await fetch("https://cevi.co.in/publication_react.php");
    if (!response.ok){
      throw new Error('HTTP error! Status: ${response.status}');
    }
    const json = await response.json();
    json.shift();
    setData(json);
  }catch(error){
    console.error("Error fetching data:", error.message);
  }
};
  useEffect(() => {
    // loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    // loading = false;
  }, [category, year, paperType]);

  return (
    <>
      <div
        className="main min-vh-100 "
        style={{ fontFamily: "Inter", backgroundColor: "" }}
      >
        <BackToTop  />
        <Navbar />
        <br />
        <br />
        <br />
        {/* <Breadcrumbs /> */}
        <div
          className="hero-pub"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${pub_background}')`,
            // backgroundSize: "fit",
            // backgroundPosition: "left bottom",
            // backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container " style={{ height: "10vh" }}></div>
          <div className="container">
            <div
              className="d-flex justify-content-start align-items-center "
              style={{
                fontWeight: 400,

                fontFamily: "Inter",
                color: "white",
                fontSize: "5vh",
              }}
            >
              Publications
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", // Initially hide for small screens
                }}
                className="d-md-block" // Display for medium and larger screens
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
              {/* //   style={{
              //     fontSize: "2.5vh",
              //     fontFamily: "Inter",
              //     color: "white",
              //   }}
              //   omitDeletionAnimation={true}
              //   repeat={Infinity}
              // /> */}
            </div>
          </div>
        </div>

        <>
          <div
            className=" min-vh-100"
            // style={{ backgroundImage: `url('${pub_background}')` }}
          >
            <div className="container text-center">
              <br />
              <br />
              <div className="row">
                <div className="col-md col-lg-4">
                  <div className="input-group mb-0">
                    <input
                      type="text"
                      // className="form-control form-control-sm rounded-0 shadow-sm"
                      className="form-control rounded-0 shadow-sm"
                      placeholder="Search by keywords"
                      id="myInput"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                </div>
                <div className="col-md-0 col-md-1 col-lg-2"></div>
                <div className="mb-0 col-md col-lg-2">
                  <div className="dropdown">
                    <button
                      className="btn btn-light w-100 border shadow-sm text-center dropdown-toggle"
                      placeholder="Paper Type"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {paperType ? paperType : "Paper Type (All)"}
                    </button>
                    <ul className="dropdown-menu" id="paper-dropdown">
                      <Paperfilter />
                    </ul>
                  </div>
                </div>

                {/* <div className="mb-0 col-md col-lg-3"> */}
                <div className="col-md col-lg-2 mb-0">
                  <div className="dropdown">
                    <button
                      className="btn btn-light w-100 border shadow-sm text-center dropdown-toggle "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      placeholder="Category"
                    >
                      {category ? category : "Category (All)"}
                    </button>
                    <ul
                      className="dropdown-menu overflow-y-auto"
                      id="category-dropdown"
                      style={{ maxHeight: "200px" }}
                    >
                      <ExcelDatabaseReader />
                    </ul>
                  </div>
                </div>

                {/* <div className="col-2 col-md-1"> */}
                <div className="col-lg-2 col-md-2  mb-0">
                  <div className="dropdown">
                    <button
                      className="yearbutton btn btn-light w-100 border text-center shadow-sm dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {year ? year : "Year (All)"}
                    </button>
                    <ul className="dropdown-menu" id="year-dropdown"
                    style={{ maxHeight: "200px" }} >
                      <YearFilter  />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="container">
              {/* {console.log(data)} */}
              {data &&
                data.map((t, index) => {
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] !== "Paper Title"
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.png`);
                    } catch {}
                    let arr = t[9].split(",");
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-12 col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img rounded-0 align-items-center"
                                alt="..."
                                style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title1"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                                {/* <div style={{ fontFamily: "Inter" }}>
                                  {arr.map((e, i) => {
                                    return (
                                      <>
                                        {e !== " " && (
                                          <Chip
                                            key={i}
                                            label={e}
                                            variant="outlined"
                                          />
                                        )}
                                        &nbsp; &nbsp;
                                      </>
                                    );
                                  })}
                                </div> */}
                                {/* <a
                                href={`${t[8]}`}
                                target="_blank"
                                className="card-link btn btn-secondary"
                                type=""
                              >
                                PDF
                              </a>
                              &nbsp;&nbsp;&nbsp;
                              <a
                                href={`${t[11]}`}
                                target="_blank"
                                className="card-link btn btn-secondary ms-0"
                              >
                                Video
                              </a>
                              &nbsp;&nbsp;&nbsp;
                              <a
                                href={`${t[12]}`}
                                target="_blank"
                                className="card-link btn btn-secondary ms-0"
                              >
                                Code
                              </a> */}
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                            {/* <div className="col-md-1 mt-3">
                              <div className="row ">
                                <div className="col-md d-flex mb-3 ">
                                  <a
                                    href={`${t[8]}`}
                                    target="_blank"
                                    className="pdf card-link btn w-100"
                                    type=""
                                  >
                                    PDF
                                  </a>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-md d-flex mb-3">
                                  <a
                                    href={`${t[10]}`}
                                    target="_blank"
                                    className="vid card-link btn  w-100"
                                    type=""
                                  >
                                    Video
                                  </a>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md d-flex mb-3">
                                  <a
                                    href={`${t[12]}`}
                                    target="_blank"
                                    className="code card-link btn w-100"
                                    type=""
                                  >
                                    Code
                                  </a>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
          </div>
        </>
        <FooterTrial  />
      </div>
    </>
  );
}

export default Publications;
