import React from "react";
import "../../Stylesheets/Carousel_Item.css";

function Carousel_Item(props) {
  return (
    <div className="carousel">
      <div className="container">
        <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-2">
          <img
            src={props.img}
            // style={{ height: "20vh", width: "20vh" }}
            className="img rounded-circle img-fluid"
            alt="..."
          />
        </div>
        <div
          className="col-md quote d-flex align-items-center"
          style={{ lineHeight: "2.5" }}
        >
          {props.testimonial}
        </div>
        <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}
                                                                                                                                         
export default Carousel_Item;
