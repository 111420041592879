import vaidya1 from "../Images/Vaidya/vaidya1.webp";
import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
import pub_background from "../Images/Publications/pub_background.webp";
import Year_filter from "../Components/Publications/Year_filter";
import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Vaidya() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  console.log(category)
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
        
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className=" d-flex justify-content-start   text-light flex-wrap "
              style={{
                fontWeight: 500,
                fontSize: "calc(1rem + 0.9vw)",
                fontFamily: "Inter",
             
              }}
            >
              Vision Group of Science and Technology (VGST):
              <br/>
              <span className="truncateText ">
              V-Vaidya: Visual-Intelligence Based Analysis of Histopathology Data Towards the Detection of Oral Cancer
              </span>
            </div>

            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
        
        <br />
        <div className="container">
        <Breadcrumbs/>
          <br/>

          <div className="row">
                  <p> 
                  The project "Visual-Intelligence Based Analysis of Histopathology Data Towards the Detection of Oral Cancer" focuses on developing a learning-based framework to assist in the analysis of histopathological data for the diagnosis of preoral and oral cancer. The project aims to leverage machine learning and artificial intelligence techniques to enhance the accuracy and efficiency of the diagnostic process, ultimately improving patient outcomes.                  </p>
          </div>
          <div className="row">
           <div className="col col-md text-center">
                  <img src={vaidya1} className="img-fluid" alt="..."/>
              </div>
          </div>
          <br/>

          <div className="row">
          <div className="container">
          Objectives of the Project "V-Vaidya Visual-Intelligence Based Analysis of Histopathology Data Towards the Detection of Oral Cancer":
          <ol>
                  <li>  
                  Develop a learning-based framework: Design and implement a comprehensive framework that utilizes machine learning and artificial intelligence techniques to assist in the analysis of histopathology data for the diagnosis of preoral and oral cancer. The framework will leverage advanced algorithms to enhance the accuracy and efficiency of the diagnostic process.

                  </li>
                  <li>
                  Generate a dataset: Create a high-quality and diverse dataset specifically tailored for AI-based histopathological analysis. The dataset will include a wide range of preoral and oral cancer cases, encompassing different stages, subtypes, and variations. This dataset will serve as a valuable resource for training and validating the developed framework.
                  
                   </li>
                  <li> 
                  Develop an annotation tool: Build a user-friendly annotation tool that facilitates the analysis of histopathological data. The tool will enable pathologists and researchers to annotate and label the key features and regions of interest in the histopathology images. This annotated data will contribute to the training and evaluation of the AI models within the framework.
                  </li>
                  <li> 
                  Demonstrate and validate the results: Demonstrate the effectiveness and reliability of the proposed framework in a clinical setting. The framework will be applied to a real-world dataset of histopathology data, and its performance will be evaluated against established diagnostic standards. The results will be compared with the expertise of human pathologists to assess the framework's diagnostic accuracy and potential for clinical deployment.
                  </li>
                  {/* <li>
              To demonstrate visual presentation of point cloud data by storytelling and virtual reality.
                   </li> */}
                </ol>
     
          </div>
          </div>
         {/* <div class="row">
              <div class="col-md-6">
                  <p>
                      
                  </p>
              </div>
              <div class="col-md-6">
                  <img src={img2} class="img-fluid" alt="Your Image"/>
              </div>
            
          </div> */}
        </div>

               {/* related publications code */}
        <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                marginTop: "1em",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  //  console.log("this",Paperfilter); 
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] !== "Paper Title" &&
                    t[14] !== null
                  ) {
                    count += 1;

                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[14].split(", ");
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] === "V-Vaidya"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}
              
              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
  

      </div>
      </>
  );
}

export default Vaidya;
