import React  from "react";
import Researchpages from "../Page_Layouts/Research_pages";
import hero from "../Images/2D/hero.webp";
import card1 from "../Images/2D/card1.webp";
import card2 from "../Images/2D/enhancement.webp";
import card3 from "../Images/2D/fish.webp";
import card4 from "../Images/2D/feature-extraction.webp";
import card5 from "../Images/2D/TSNE.webp";
import card6 from "../Images/2D/Image_quality_assessment.webp";




function Two_Dimensional() {

  return (
    <>
      <Researchpages
        hero={hero}
        main="2D Data Acquisition and Processing"
        
        desc1="In the domain of 2D image acquisition and processing, our research primarily delves into image enhancement and restoration, with a specific focus on addressing challenges in low-light and underwater environments. The core objective revolves around the development of innovative imaging techniques to establish ground truth, serving as input for our ISP pipeline and facilitating enhancement and restoration frameworks across diverse imaging scenarios. Our current endeavors center on the proposition and design of pioneering deep generative algorithms, aiming to bolster image quality, precision, and efficiency."
        desc2="Moreover, our research extensively harnesses the capabilities of deep generative models, not only for image enhancement and denoising but also for data augmentation, thereby significantly refining the interpretability and quality of images captured in low-light conditions. The implications of our findings resonate across various domains, including but not limited to digital heritage preservation, agriculture, smart manufacturing, medical imaging, surveillance, remote sensing, computer vision, and robotics, underscoring the wide-ranging impact of our contributions."
        
        link1="/Research/2d/Two_Dimensional_Page1"
        link2="/Research/2d/Two_Dimensional_Page2"
        link3="/Research/2d/Two_Dimensional_Page3"
        link4="/Research/2d/Two_Dimensional_Page4"
        link5="/Research/2d/Two_Dimensional_Page5"
        link6="/Research/2d/Two_Dimensional_Page6"
        cardtitle1="2D Data Acquisition / Capture Setup"
        cardimg1={card1}
        // carddesc1="Enabling the development of advanced 2D data capture setups. This involves selecting or designing the appropriate visual sensors, cameras, and imaging systems to ensure high-quality data acquisition. Factors such as resolution, frame rate, and sensor type are carefully considered to optimize data collection." 
        cardtitle2="Image Restoration and Enhancement"
        cardimg2={card2}
        // carddesc2="Employ image restoration and enhancement techniques to improve the quality of acquired images. This includes denoising, deblurring, and contrast adjustment to remove artefacts and enhance the visual clarity of images. High-quality input data is crucial for accurate downstream processing."
        cardtitle3="Learning Representation of 2D Data"
        cardimg3={card5}
        // carddesc3="Developing effective methods for representing 2D data in a form suitable for machine learning and deep learning models. We extract features and create feature vectors that capture relevant information, enabling efficient analysis and interpretation."
        cardtitle7="Detection, Localization, and Tracking"
        cardimg7={card3}
        // carddesc7="Design algorithms and models for the detection, localization, and tracking of objects or regions of interest within 2D visual data. This includes tasks like object recognition, spatial localization, and temporal tracking, which are fundamental for various applications, including computer vision and robotics."
        cardtitle5="Filtering, Classification, and Categorization of 2D Data"
        cardimg5={card4}
        // carddesc5="Apply filtering, classification, and categorization techniques to the processed 2D data. This enables the organization of data into meaningful groups, making it easier to extract insights and make decisions. Tasks such as object categorization and event recognition fall under this category."
        cardtitle6="Quality Analysis of Images"
        cardimg6={card6}
        // carddesc6="Ensuring the quality and reliability of acquired images is a key aspect of our research. We develop methods and metrics to assess image quality, including factors like sharpness, color fidelity, and noise levels. Quality analysis aids in identifying and addressing issues in the image acquisition process."
        // cardtitle7="Morphological Analysis"
        // cardimg7={card6}
        // carddesc7="We understand the importance of effective data visualization in conveying insights from 2D data. Our research focuses on developing visualization techniques, interactive interfaces, and data visualization tools that enable intuitive exploration, analysis, and presentation of complex 2D data sets."
      />
      
    </>
  );
}

export default Two_Dimensional;
