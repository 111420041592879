// import React from "react";
import React, { useRef, useEffect  } from "react";
import Research_pages from "../Page_Layouts/Research_pages";
import hero from "../Images/2D/hero.webp";
import card1 from "../Images/3D/green_room.webp";
import card2 from "../Images/3D/card2.webp";
import card3 from "../Images/3D/card3.webp";
import card5 from "../Images/3D/Interaction.webp";
import card6 from "../Images/3D/card6.webp";
import card7 from "../Images/3D/AICTE.webp";
import { Link } from "react-router-dom";

function Three_dimentional() {
  return (
    <>
      <Research_pages
        hero={hero}
        main="3D Data Acquisition and Processing"
        desc1="In recent years, dealing with the huge diversity and complexity of 3D data has become the main research challenge for various applications in computer vision, graphics, robotics, Meta-Verse, archaeology, agriculture, forestry, AR/VR/XR/MR, autonomous vehicles, Industry 4.0, etc.  One key approach that researchers have found promising is to decompose the complex 3D data into smaller and easier composable subcomponents. For example, this could be decomposition into basic shapes to understand the geometry/surface, or this could be a decomposition of an object into spatially localized parts and a sparse set of relationships between them, or in scenes, it could be a scene graph, where rich inter-object relationships are described, or in robotics decomposition into separate parts of concepts or submodules that are related by spatial, causal, or semantic relationships. Our current research focus is on 3D data acquisition and processing is dedicated to enhancing machines' ability to perceive, analyze, and interact with the three-dimensional world. We focus on improving data quality, extracting meaningful features, and developing algorithms across a spectrum of applications. Ultimately, our aim is to empower machines with a deeper understanding of the 3D visual data they encounter, opening doors to innovative and impactful applications in various fields."
        // desc2="Explore our website to learn more about our research projects, publications, and collaborations. Contact us today to discuss potential partnerships, research opportunities, or to explore how our expertise can benefit your specific needs. Together, let's unlock the power of 3D data acquisition and processing and drive innovation in your domain."
        // img1={img1}
        // img2={img2}
        link1="/Research/3d/Three_Dimensional_Page1"
        link2="/Research/3d/Three_Dimensional_Page2"
        link3="/Research/3d/Three_Dimensional_Page3"
        link4="/Research/3d/Three_Dimensional_Page4"
        link5="/Research/3d/Three_Dimensional_Page5"
        link6="/Research/3d/Three_Dimensional_Page6"
        cardtitle1="3D Data Acquisition"
        cardimg1={card1}
        // carddesc1="Our research begins with the development of advanced 3D data capture techniques and technologies. This involves selecting or designing appropriate sensors, LiDAR systems, or depth cameras to acquire accurate and comprehensive 3D data."
        cardtitle3="Learning Representation of 3D Data"
        cardimg3={card2}
        // carddesc3="Focus on devising methods for representing 3D data in formats suitable for machine learning and deep learning models. This includes generating feature vectors or embeddings that encapsulate critical information about 3D objects or scenes."
        cardtitle2="3D Reconstruction and Recreation"
        cardimg2={card3}
        // carddesc2="Reconstructing and recreating 3D scenes or objects from acquired data. This process involves creating detailed 3D models from point clouds or depth maps, enabling a better representation of the real-world environment."
        cardtitle4="Object Detection and Recognition in 3D"
        // cardimg4={card4}
        // carddesc4="Our expertise extends to the field of object detection and recognition in 3D data. We develop algorithms and techniques to identify and localize objects within 3D scenes or point clouds. This area includes 3D object detection, instance segmentation, and pose estimation, enabling applications such as robotics, autonomous driving, industrial automation, and augmented reality."
        cardtitle7="3D Object Detection and Tracking"
        cardimg7={card5}
        // carddesc7="Development of algorithms and models for the accurate detection and tracking of objects within three-dimensional spaces. These capabilities are vital for applications like autonomous vehicles, robotics, and augmented reality."
        cardtitle5="Refinement of 3D Data"
        cardimg5={card6}
        // carddesc5="Develop techniques for enhancing and preprocessing 3D data to improve its quality. Techniques such as denoising, calibration, and alignment are applied to eliminate errors and inconsistencies in the data."
        cardimg6={card7}
        // carddesc6="Employ filtering, classification, and categorization techniques to organize and classify 3D data based on object attributes, shapes, or other relevant characteristics. This categorization is essential for tasks such as object recognition and scene understanding."
        cardtitle6="3D Data Filtering, Classification, and Categorization"
      />
    </>
  );
}

export default Three_dimentional;
