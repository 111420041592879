
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
// import research_background from "../Images/Research/research-background.webp";
import research_background from "../Images/About_Us/about-background.webp";
import { TypeAnimation } from "react-type-animation";
import Footer_Trial from "../Components/Common/Footer_Trial";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Consultancy() {
  return (
      <>
      <div className="min-vh-100" style={{ backgroundColor: "white" }}>
        <Navbar />
        <br />
        <br />
        <br />

        <div
          className="hero-research"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${research_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start align-items-center text-light"
              style={{
                fontWeight: 500,
                fontFamily: "Inter",
                // fontSize: "5vh",
              }}
            >
             Consultancy Projects
            </div>
            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
        
        <br />
       

        <div className="research-content">
          <div className="container">
          <Breadcrumbs/>
     

            <div
              className="title"
              style={{
                // fontSize: "4vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                textAlign:"center",
              }}
            >
              Completed Projects
            </div>
            <div className="row">
              <ol>
              <div className="container">
                <li>  
                
                <b>Samsung:  Large scale reconstruction using Drone images ( 2017-19)</b>
                <br/>
                Objective is to develop pipeline for large scale 3D reconstruction which includes 3D reconstruction pipeline and 3D data processing methods and such the reconstructed models can be used for AR/VR applications.  We have reconstructed more than 30 sites with a height ranging from 7ft to 70ft.
                
                </li>
                <li>
                
                <b>Continental India Ltd: ‘Restoration of motion blur and image SR for autonomous driving’ (2016-2019)</b>
                <br/>
                We have developed early detection of signboards using super-resolution of videos and removal of motional blur. 

                </li>
                </div>
              </ol>
            </div>

          
          </div>
        </div>
       
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Footer_Trial />
      </div>
    </>
  );
}

export default Consultancy;
