import React from "react";
import "../../Stylesheets/Research_Cards.css";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <>
      <div
        className="card h-100 research-cards border-0 "
        style={{
          color: "#3d5066",
          marginInline: "1em",
          maxWidth: "100%",
          backgroundColor: "",
        }}
      >
        <img 
          src={props.img}
          className=" bg-white card-img-top rounded-0 img-fluid"
          alt="..."
        />
        
        <div className="card-body p-0 ">
          <div
            className="card-title"
            style={{
              fontSize: "3vh",
              fontFamily: "Inter",
              fontWeight: "500",
              textAlign: "left",
              color: "#05445E",
              marginTop: "1em", // Add margin-top to create space
            }}
          >
            <Link
              to={props.link}
              style={{ textDecoration: "none", color: "inherit" }}
              className="stretched-link"
            >
              {props.title}
            </Link>
          </div>
          <p
            className="card-text"
            style={{
              fontFamily: "Inter",
              textAlign: "justify",
              lineHeight: "1.6",
            }}
          >
            {props.desc}
          </p>
        </div>
      </div>
    </>
  );
}

export default Cards;
