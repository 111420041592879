// import React, {useEffect, useRef } from "react";
import React from "react";
import Backtotop from "../Components/Common/BackToTop";
import projects_background from "../Images/About_Us/about-background.webp";
import "../Stylesheets/Projects.css";
import Navbar from "../Components/Common/Navbar";
import sponsor from "../Images/Home/Sponsored.webp";
import collaborative from"../Images/Home/Collabrative.webp";
import consultancy from "../Images/Home/Consultancy.webp";
import academic from "../Images/Projects/academic.webp";
import Cards from "../Components/Projects/Cards";
import { TypeAnimation } from "react-type-animation";
import FooterTrial from "../Components/Common/Footer_Trial";
// import { Link } from "react-router-dom";
// import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Projects() {
  return (
    <>
      <div
        className="min-vh-100"
        style={{ fontFamily: "Inter" }}
      >
        <Backtotop />

        <div
          className="background-projects"
        >
          <Navbar />
          <br />
          <br />
          <br />
          {/* <Breadcrumbs /> */}
          <div
            className="hero-project"
            id=""
            style={{
              minHeight: "30vh",
              backgroundImage: `url('${projects_background}')`,
            }}
          >
            <div className="container">
              <div className="container " style={{ height: "10vh" }}></div>
              <div
                className="d-flex justify-content-start  align-items-center text-light"
                style={{
                  fontWeight: 500,
                  fontFamily: "Inter",
                  fontSize: "5vh",
                }}
              >
                Projects
              </div>
              <div style={{}}>
                <TypeAnimation
                  sequence={[
                    "#Empowering Innovation through Visual Perception",
                    500,
                    "#Redefining Possibilities with Visual Intelligence",
                    500,
                    "#Advancing Visual Intelligence through Innovation",
                    500,
                    "#Where pixels inspires brilliance",
                    500,
                  ]}
                  style={{
                    fontSize: "1.352rem",
                    fontFamily: "Inter",
                    color: "white",
                    display: "none", 
                  }}
                  className="d-md-block"
                  omitDeletionAnimation={true}
                  repeat={Infinity}
                />
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className="container">
          <div className="d-flex row justify-content-center">
            {/* <div className="col-md-6 p-0"> */}
            <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={sponsor}
                title="Sponsored Research Projects"
                id="01"
                link="/projects/sponsored"
                desc="Research Projects that have been completed and were sponsored by an external firm."
              />
            </div>
            <div className="col-lg col-md-6 p-0 mb-3">
              <Cards
                img={collaborative}
                title="Collaborative Research Projects"
                id="02"
                // link="/projects/collaborative"
                desc="Research projects that been completed in collaboration with another lab."
              />
            </div>
            <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={consultancy}
                title="Consultancy Projects"
                id="03"
                link="/projects/Consultancy"
                desc="Projects that have been completed with the help of a consultant."
              />
            </div>
            <div className="col-lg col-md-6 p-0 mb-5">
              <Cards
                img={academic}
                title="Other Projects"
                id="03"
                // link="/research/multimodal"
                desc="Projects that have been completed with the help of a consultant."
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <FooterTrial />
      </div>
    </>
  );
}

export default Projects;
