// import React from "react";
import React, { useRef, useEffect  } from "react";
import linkedin from "../Images/Contact/linkedin-logo-inverted.webp";
import pin from "../Images/Contact/location-pin.webp";
import phone from "../Images/Contact/phone-call.webp";
import email from "../Images/Contact/email.webp";
// import contact_background from "../Images/Contact/contact-background.webp";
import contact_background from "../Images/About_Us/about-background.webp";
import twitter from "../Images/Contact/twitter-inverted.webp";
import "../Stylesheets/Contact.css";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import FooterTrial from "../Components/Common/Footer_Trial";
import { Link } from "react-router-dom";

// import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Contact_Trial() {
  return (
    <>
      <div className="min-vh-100"
       style={{ backgroundColor: "", fontFamily: "Inter" }}>
        <Navbar />
        <br />
        <br />
        <br />
        {/* <Breadcrumbs /> */}
        <div
          className="hero-project"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${contact_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="d-flex justify-content-start text-light align-items-center"
              style={{
                fontWeight: 500,
                fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
              Contact
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", 
                }}
                className="d-md-block"
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <br />
        {/* <div className="row p-5">
          <div className="col-md-6 d-flex justify-content-center">
            <img src={gif1} alt="" className="img-fluid border-0 " />
          </div>
          <div className="col-md-6 ">
            <div className=" fs-1 d-flex align-items-center">
              Entrance to the KLE BVB Campus
            </div>
            <div className="fs-5" style={{ lineHeight: "1.6" }}>
              KLE Technological University (KLE Tech) has its roots in B. V.
              Bhoomaraddi College of Engineering and Technology, Hubli (BVB),
              one of the premier engineering institutions of Karnataka. The
              founding organization KLE Society, Belgaum, established BVB
              college in 1947 with an aspiration of creating an institution that
              would lay the foundation of modern engineering education in
              northern region of Karnataka.
            </div>
          </div>
        </div> */}
        <div className="container" >
          <div className="  border-0 " style={{ backgroundColor: "" }}>
            <div className="row  ">
              <div className="col-md-4 col-sm-12 mt-4" style={{ paddingRight: "1.35rem" }}>
                <div className=" border-0">
                  <div className="card-body">
                    <div className="card-text text-dark">
                        {/* <div className="row"> 
                          <div className="col-md-3 pt-2 d-flex justify-content-end align-items-start"> 
                          <div className="col-3 pt-2 d-flex justify-content-end align-items-start">
                            <img className="img-fluid" src={linkedin}/>
                          </div>

                          <div className="col-9">
                            <p
                              className=" mb-0 "
                              style={{ fontFamily: "Inter", color: "#05445E" , fontSize: "3vh" }}
                            >
                              LinkedIn
                            </p>

                            <p>center-of-excellence-in-visual-intelligence-cevi</p>
                          </div>
                        </div>  */}

                        <div className="row d-flex justify-content-center align-items-start">
                          <div className="col-2 pt-2 d-flex align-items-start" >
                            <a
                              href="https://in.linkedin.com/company/kletech-center-of-excellence-in-visual-intelligence-cevi"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img className="img-fluid" src={linkedin} alt="LinkedIn" />
                            </a>
                          </div>

                          <div className="col " >
                            <a
                              style={{ textDecoration: "none", color: "inherit" }}
                              href="https://in.linkedin.com/company/kletech-center-of-excellence-in-visual-intelligence-cevi"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p
                                className=" mb-0 "
                                style={{ fontFamily: "Inter", color: "#05445E", fontSize: "3vh" }}
                              >
                                LinkedIn
                              </p>
                            </a>
                            <a
                              style={{ textDecoration: "none", color: "inherit" }}
                              href="https://in.linkedin.com/company/kletech-center-of-excellence-in-visual-intelligence-cevi"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>center-of-excellence-in-visual-intelligence-cevi</p>
                            </a>
                          </div>
                        </div>


                      <div className="row d-flex justify-content-center align-items-start">
                        <div className="col-2 pt-2 d-flex align-items-start">
                          <a
                            href="https://twitter.com/kletech_cevi"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img className="img-fluid" src={twitter} alt="Twitter" />
                          </a>
                        </div>

                        <div className="col">
                          <a
                            style={{ textDecoration: "none", color: "inherit" }}
                            href="https://twitter.com/kletech_cevi"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p
                              className=" mb-0 "
                              style={{
                                fontFamily: "Inter",
                                color: "#05445E",
                                fontSize: "3vh",
                              }}
                            >
                              Twitter
                            </p>
                          </a>

                          <a
                            style={{ textDecoration: "none", color: "inherit" }}
                            href="https://twitter.com/kletech_cevi"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>kletech_cevi</p>
                          </a>
                        </div>
                      </div>


                      <div className="row d-flex justify-content-center align-items-start">
                        <div className="col-2 pt-2 d-flex  align-items-start">
                          <a
                            style={{ textDecoration: "none", color: "inherit" }}
                            href="mailto:cevi@kletech.ac.in"
                          >
                            <img className="img-fluid " src={email} />
                          </a>
                        </div>

                        <div className="col">
                          <a
                            style={{ textDecoration: "none", color: "inherit" }}
                            href="mailto:cevi@kletech.ac.in"
                          >
                            <p
                              className=" mb-0 "
                              style={{
                                fontFamily: "Inter",
                                color: "#05445E",
                                fontSize: "3vh",
                              }}
                            >
                              Email
                            </p>
                          </a>

                          <a
                            style={{ textDecoration: "none", color: "inherit" }}
                            href="mailto:cevi@kletech.ac.in"
                          >
                            <p>cevi@kletech.ac.in</p>
                          </a>
                        </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-start">
                          <div className="col-2 pt-2 d-flex  align-items-start">
                            <a
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              href="tel:+918362378410"
                            >
                              <img className="img-fluid" src={phone} />
                            </a>
                          </div>
                          <div className="col">
                            <a
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              href="tel:+918362378410"
                            >
                              <p
                                className=" mb-0 "
                                style={{
                                  fontFamily: "Inter",
                                  color: "#05445E",
                                  fontSize: "3vh",
                                }}
                              >
                                Phone
                              </p>
                            </a>

                            <a
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              href="tel:+918362378410"
                            >
                              <p>+91 836 2378 410</p>
                            </a>
                          </div>
                        </div>
                        {/* <div className="row d-flex justify-content-center align-items-start">
                          <div className="col-3 pt-2 d-flex justify-content-end align-items-start">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to=""
                            >
                              <img className="img-fluid" src={pin} />
                            </Link>
                          </div>
                          <div className="col-9">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to=""
                            >
                              <p
                                className=" mb-0 "
                                style={{
                                  fontFamily: "Inter",
                                  color: "#05445E",
                                  // color: "#05445E",
                                  fontSize: "3vh",
                                }}
                              >
                                Address
                              </p>
                            </Link>

                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                fontFamily: "Inter",
                              }}
                              to=""
                            >
                              <p>
                                <>
                                  Centre of Excellence in Visual Intelligence
                                  (CEVI){" "}
                                </>{" "}
                                <br />
                                2nd floor, C-Lite building, KLE Technological
                                University, BVB Campus, Vidya Nagar, Hubballi
                                580031, Karnataka, INDIA.{" "}
                              </p>
                            </Link>
                          </div>
                        </div> */}
                      <div className="row d-flex justify-content-center align-items-start">
                      <div className="col-2 pt-2 d-flex  align-items-start">
                        <a
                          href="https://www.google.com/maps?q=Centre+of+Excellence+in+Visual+Intelligence,+KLE+Technological+University,+BVB+Campus,+Vidya+Nagar,+Hubballi,+Karnataka,+India"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <img className="img-fluid" src={pin} alt="Map Pin" />
                        </a>
                      </div>
                      <div className="col">
                        <a
                          href="https://www.google.com/maps?q=Centre+of+Excellence+in+Visual+Intelligence,+KLE+Technological+University,+BVB+Campus,+Vidya+Nagar,+Hubballi,+Karnataka,+India"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit", fontFamily: "Inter" }}
                        >
                          <p className="mb-0" style={{ color: "#05445E", fontSize: "3vh" }}>
                            Address
                          </p>
                        </a>

                        <a
                          href="https://www.google.com/maps?q=Centre+of+Excellence+in+Visual+Intelligence,+KLE+Technological+University,+BVB+Campus,+Vidya+Nagar,+Hubballi,+Karnataka,+India"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit", fontFamily: "Inter" }}
                        >
                          <p>
                            <>
                              Centre of Excellence in Visual Intelligence (CEVI) <br />
                              2nd floor, C-Lite building, KLE Technological University, BVB Campus,
                              Vidya Nagar, Hubballi 580031, Karnataka, INDIA.{" "}
                            </>
                          </p>
                        </a>
                      </div>
                    </div>
                    
                    <br />
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="frame col-md-8 d-flex align-items-center">
                  <iframe
                    height="100%"
                    width="100%"
                    className="map rounded-3  w-100 "
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.1817541175806!2d75.12599530236946!3d15.37090255462191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d14ba920f7c9%3A0x9ec0ab31cd15ee5!2sKLE%20Tech.%20Samsung%20SEED%20(Student%20Eco-system%20for%20Engineered%20Data)%20Lab%2C%20CEVI%20(Center%20Of%20Excellence%20in%20Visual%20Intelligence)!5e0!3m2!1sen!2sin!4v1687414074827!5m2!1sen!2sin"
                  ></iframe>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* <div
            className=""
            style={{ backgroundColor: "", paddingBlock: "3em" }}
          >
            <div className="title fs-1 d-flex justify-content-center text-light"></div>
            <div className="row gy-5 d-flex justify-content-center">
              <div className="col-md-4 d-flex justify-content-center p-0">
                <img src={gif1} alt="" className="img-fluid" />
              </div>
              <div className="col-md-4 d-flex justify-content-center p-0">
                <img src={gif2} alt="" className="img-fluid" />
              </div>
              <div className="col-md-4 d-flex justify-content-center p-0">
                <img src={gif3} alt="" className="img-fluid" />
              </div> */}
            {/* </div>
          </div> */}
        </div>
        <FooterTrial />
      </div>
    </>
  );
}

export default Contact_Trial;
