// import React, { useContext, useEffect, useState, useRef } from "react";
import React from "react";
import twitter from "../Images/People/twitter.webp";
import github from "../Images/People/github.webp";
import linkedin from "../Images/People/linkedin.webp";
import placeholder from "../Images/People/placeholder.webp";
import people_background from "../Images/About_Us/about-background.webp";
import BackToTop from "../Components/Common/BackToTop";
import Navbar from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import faculty1 from "../Images/People/Team Members/faculty1.webp";
import faculty2 from "../Images/People/Team Members/faculty2.webp";
import faculty3 from "../Images/People/Team Members/faculty3.webp";
import faculty4 from "../Images/People/Team Members/faculty4.webp";
import faculty5 from "../Images/People/Team Members/faculty5.webp";
import Dikshit from "../Images/People/Team Members/Dikshit.webp";
import Nikhil from "../Images/People/Team Members/Nikhil.webp";
import Nitish from "../Images/People/Team Members/Nitish.webp";
import Sampada from "../Images/People/Team Members/Sampada.webp";
import Chaitra from "../Images/People/Team Members/Chaitra.webp";
import img1 from "../Images/Alumni/PlaceHolder/Abhijeet Ganapule.webp"
import img2 from "../Images/Alumni/PlaceHolder/Abhishek Sangam.webp"
import img3 from "../Images/Alumni/PlaceHolder/Akshay Gunari.webp"
import img4 from "../Images/Alumni/PlaceHolder/Channabasappa Konin.webp"
import img5 from "../Images/Alumni/PlaceHolder/Samanvitha Karanth.webp"
import img6 from "../Images/Alumni/PlaceHolder/Shanthika Naik.webp"
import img7 from "../Images/Alumni/PlaceHolder/Soumya Jahagirdar.webp"
import img8 from "../Images/Alumni/PlaceHolder/Sujaykumar Kulkarni.webp"
import img9 from "../Images/Alumni/PlaceHolder/T Santoshkumar.webp"
import { Link } from "react-router-dom";
import "../Stylesheets/People.css";
import Footer_Trial from "../Components/Common/Footer_Trial";

function People() {
  return (
    <>
    
      <div
        className=" main min-vh-100"
        style={{ fontFamily: "Inter" }}
      >
        <BackToTop />
        <Navbar />
        <br />
        <br />
        <br />
        <div
          className="hero"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${people_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="d-flex justify-content-start text-light  align-items-center"
              style={{
                fontWeight: 500,   
                fontFamily: "Inter",
                fontSize: "5vh",
              }}
            >
              People
            </div>
            <div>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", 
                }}
                className="d-md-block"
                speed={50}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        
        <br />
        <div className="container">
          <div className="mb-4 mt-4">
            <p className="fs-2 text-center">Team Members</p>
          </div>
          <br />
          <div className=" row row-cols-lg-5 row-cols-md-5 row-cols-1 d-flex row text-center align-items-start mb-4">
            <div className="col-6 col-md">
              <a href="">
                <img
                  src={faculty5}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Dr. Uma Mudenagudi</p>
              <p className="pt-1 b-0 text-muted">Director</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
            <div className="col-6 col-md">
              <a href="">
                <img
                  src={faculty1}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Dr. Sujata C</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <div className="social-links">
                <a href="facebook.com "  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
            <div className="col-6 col-md">
              <a href="">
                <img
                  src={faculty4}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Dr. Padmashri Desai</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
            <div className="col-6 col-md ">
              <a href="">
                <img
                  src={faculty3}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Ramesh Ashok Tabib</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
            <div className="col-6 col-md">
              <a href="">
                <img
                  src={faculty2}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Kaushik Mallibhat</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <div className="social-links"  target="_blank" rel="noopener noreferrer">
                <a href="facebook.com">
                  <img className="me-2 mb-3 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-3 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-3 img-fluid" src={github} />
                </a>
              </div>
            </div>

            <div className="col-6 col-md">
              <a href="">
                <img
                  src={Chaitra}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Chaitra Desai</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
            {/* </div> */}


          {/* <div className="row row-cols-lg-6 row-cols-md-5 row-cols-1 d-flex row text-center align-items-start mb-4"> */}
            
          <div className="col-6 col-md">
              <a href="">
                <img
                  src={Dikshit}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Dikshit Hegde</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>

            <div className="col-6 col-md">
              <a href="">
                <img
                  src={Nikhil}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Nikhil Akalwadi</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>

            

            <div className="col-6 col-md">
              <a href="">
                <img
                  src={Nitish}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Nitishkumar Upasi</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>

            <div className="col-6 col-md">
              <a href="">
                <img
                  src={Sampada}
                  className="rounded-circle img-fluid"
                  alt=""
                  style={{ width: '90%'}} 
                />
              </a>
              <p className="pt-4 mb-0 fw-bold">Sampada Malagi</p>
              <p className="pt-1 b-0 text-muted">Research Assistant</p>
              <div className="social-links">
                <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                  <img className="me-2 mb-5 img-fluid" src={twitter} />
                </a>
                <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                  <img className="mb-5 img-fluid" src={linkedin} />
                </a>
                <a href="github.com"  target="_blank" rel="noopener noreferrer">
                  <img className="ms-2 mb-5 img-fluid" src={github} />
                </a>
              </div>
            </div>
          </div>
         
          {/* <div className="row text-center align-items-start mb-4">
            <div className="col-md">
              <a href="">
                <img src={placeholder} className="rounded img-fluid" alt="" />
              </a>
              <p className="pt-4 mb-0 fw-bold">Umadevi F M</p>
              <p className="pt-1 b-0 text-muted">Research Faculty</p>
              <a href="facebook.com">
                <img className="me-2 mb-3" src={twitter} />
              </a>
              <a href="linkedin.com">
                <img className="mb-3" src={linkedin} />
              </a>
              <a href="github.com">
                <img className="ms-2 mb-3" src={github} />
              </a>
            </div>
            <div className="col-md">
              <a href="">
                <img src={placeholder} className="rounded img-fluid" alt="" />
              </a>
              <p className="pt-4 mb-0 fw-bold">Chaitra Desai</p>
              <p className="pt-1 b-0 text-muted">Research Scholar</p>
              <a href="facebook.com">
                <img className="me-2 mb-3" src={twitter} />
              </a>
              <a href="linkedin.com">
                <img className="mb-3" src={linkedin} />
              </a>
              <a href="github.com">
                <img className="ms-2 mb-3" src={github} />
              </a>
            </div>
          </div> */}

          <br />
          <br />
          
          <div className="text-center mb-4 mt-2">
            <p className="fs-2">Alumni</p>
          </div>
          <br />
          {/* <div className="justify-content-center"></div> */}
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-touch="false"
          >
            <div className="carousel-inner text-center mb-5">
              <div className="carousel-item active" data-bs-interval="4000">
                <div className="row ml-2">
                  <div className="col-md-1"></div>
                  <div className="col-6 col-md ">
                    <img src={img1} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold ">Abhijeet Ganapule</p>
                    {/* <p className="pt-1 b-0 text-muted d-none d-md-block">Hardware design engineer, Germany </p> */}
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Hardware design engineer,VALEO Telematik und Akustik GmbH, Germany </p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img
                          className="img-fluid me-2 mb-5"
                          src={twitter}
                          // style={{ width: "10%" }}
                        />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img
                          className="img-fluid mb-5"
                          src={linkedin}
                          // style={{ width: "10%" }}
                        />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img
                          className="img-fluid ms-2 mb-5"
                          src={github}
                          // style={{ width: "10%" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img2} className="rounded-circle img-fluid" alt=""  style={{ width: '90%'}} />
                    <p className="pt-4 mb-0 fw-bold">Abhishek Sangam</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Technical Specialist (CG/XR) in Sony</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img3} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">Akshaykumar Gunari</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Software System Designer - 2 @ AMD</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img4} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">Channabasappa Konin</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Lead Engineer, BYJU's</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="4000">
                <div className="row ml-2">
                  <div className="col-md-1"></div>
                  {/* <div className="col-md">
                    <img src={img5} className="rounded-circle img-fluid" alt="" />
                    <p className="pt-4 mb-0 fw-bold">Samanvitha Karanth</p>
                    <p className="pt-1 b-0 text-muted">Founder MTS at Aivu Pvt. Ltd</p>
                    <a href="facebook.com">
                      <img className="me-2 mb-3" src={twitter} />
                    </a>
                    <a href="linkedin.com">
                      <img className="mb-3" src={linkedin} />
                    </a>
                    <a href="github.com">
                      <img className="ms-2 mb-3" src={github} />
                    </a>
                  </div> */}
                  <div className="col-6 col-md">
                    <img src={img9} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">T Santoshkumar</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Senior Software Engineer, Multicoreware</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img6} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">Shanthika Naik</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Master's student at IIIT Hyderabad</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img7} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">Soumya Shamarao Jahagirdar</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Master's student at IIIT Hyderabad</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com" target="_blank" rel="noopener noreferrer" >
                        <img className="img-fluid  mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-md">
                    <img src={img8} className="rounded-circle img-fluid" alt="" style={{ width: '90%'}}  />
                    <p className="pt-4 mb-0 fw-bold">Sujaykumar Kulkarni</p>
                    <p className="pt-1 b-0 text-muted d-none d-md-block">Software Developer at MathWorks</p>
                    <div className="social-links" style={{paddingTop: "10px"}}>
                      <a href="facebook.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid me-2 mb-5" src={twitter} />
                      </a>
                      <a href="linkedin.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  mb-5" src={linkedin} />
                      </a>
                      <a href="github.com"  target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid  ms-2 mb-5" src={github} />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div>
              {/* <div className="carousel-item" data-bs-interval="4000">
                <div className="row ml-2">
                  <div className="col-1"></div>
                  <div className="col">
                    <img src={img9} className="rounded-circle img-fluid" alt="" />
                    <p className="pt-4 mb-0 fw-bold">T Santoshkumar</p>
                    <p className="pt-1 b-0 text-muted">Senior Software Engineer, Multicoreware</p>
                    <a href="facebook.com">
                      <img className="me-2 mb-3" src={twitter} />
                    </a>
                    <a href="linkedin.com">
                      <img className="mb-3" src={linkedin} />
                    </a>
                    <a href="github.com">
                      <img className="ms-2 mb-3" src={github} />
                    </a>
                  </div>
                  <div className="col-md">
                    <img src={placeholder} className="rounded-circle" alt="" />
                    <p className="pt-4 mb-0 fw-bold">Name</p>
                    <p className="pt-1 b-0 text-muted">Research Faculty</p>
                    <a href="facebook.com">
                      <img className="me-2 mb-3" src={twitter} />
                    </a>
                    <a href="linkedin.com">
                      <img className="mb-3" src={linkedin} />
                    </a>
                    <a href="github.com">
                      <img className="ms-2 mb-3" src={github} />
                    </a>
                  </div> 
                   <div className="col-md">
                    <img src={placeholder} className="rounded-circle" alt="" />
                    <p className="pt-4 mb-0 fw-bold">Name</p>
                    <p className="pt-1 b-0 text-muted">Research Faculty</p>
                    <a href="facebook.com">
                      <img className="me-2 mb-3" src={twitter} />
                    </a>
                    <a href="linkedin.com">
                      <img className="mb-3" src={linkedin} />
                    </a>
                    <a href="github.com">
                      <img className="ms-2 mb-3" src={github} />
                    </a>
                  </div>
                  <div className="col-md">
                    <img src={placeholder} className="rounded-circle" alt="" />
                    <p className="pt-4 mb-0 fw-bold">Name</p>
                    <p className="pt-1 b-0 text-muted">Research Faculty</p>
                    <a href="facebook.com">
                      <img className="me-2 mb-3" src={twitter} />
                    </a>
                    <a href="linkedin.com">
                      <img className="mb-3" src={linkedin} />
                    </a>
                    <a href="github.com">
                      <img className="ms-2 mb-3" src={github} />
                    </a>
                  </div>
                  <div className="col-md-1"></div>
                </div>
              </div> */}
            </div> 

            <button
              style={{ width: "50px", height: "50px" , top: "45%" }}
              className="carousel-control-prev position-absolute  start-0 translate-middle-y "
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                className="bi bi-arrow-left-short"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
              <span className="visually-hidden">Previous</span>
            </button>

            <button
              style={{ width: "50px", height: "50px" , top: "45%" }}
              className="carousel-control-next position-absolute  end-0 translate-middle-y"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                className="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* {/* <br /> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer_Trial />
      </div>
    </>
  );
}

export default People;
