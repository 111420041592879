// import React from "react";
import React from "react";
import Navbar_trial from "../Components/Common/Navbar";
import { TypeAnimation } from "react-type-animation";
import seed_background from "../Images/SEED/seed-background.webp";
import team from "../Images/SEED/seed_team.webp";
import Footer_Trial from "../Components/Common/Footer_Trial";
import { Link } from "react-router-dom";

function Seed_Trial() {
  return (
    <>
      <div style={{}}>
        <Navbar_trial />
        <br />
        <br />
        <br />

        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${seed_background}')`,
            fontFamily: "Inter",
            lineHeight: "2"
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="d-flex justify-content-start text-light  align-items-center "
              style={{
                fontWeight: 500,
                fontSize: "5vh",
                fontFamily: "Inter",
                lineHeight: "1"
              }}
            >
              Seed
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row"
            style={{ backgroundColor: "", paddingBlock: "3em" }}
          >
            <br />
            <br />
            <div className="col-md-6 " style={{lineHeight: "1"}}>
              <div
                className="title"
                style={{ fontFamily: "DM Sans" , color: "#05445E" , fontSize: "5vh" , fontWeight: "500" }}
              >
                SEED
              </div>
              <p className="" style={{ fontFamily: "DM Sans"  , fontSize: "5vh" , color: "#05445E" , fontWeight: "500" }}>
                Student Ecosystem of Engineered Data
              </p>
              <p className="" style={{lineHeight: "2"}}>
                CEVI along with the most trusted consumer electronics and
                smartphone brand Samsung established a collaborative lab called
                SEED (Student Ecosystem for Engineered Data) lab to stimulate
                the Indian innovation ecosystem by building capabilities among
                students, promote industry-academia collaboration, and work on
                projects related to Data Engineering and Artificial
                Intelligence. The ecosystem with industry collaborations and
                societal interactions spirally builds the competencies and
                skills, making students industry-ready. In addition, this
                initiative also engages homemakers and NGOs during data
                capturing, promoting the growth of the local ecosystem.{" "}
              </p>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img src={team} alt="" className="img-fluid rounded-3" />
            </div>
          </div>

          <div
            className="row "
            style={{ backgroundColor: "", paddingBlock: "3em" }}
          >
            <br />
            <br />
            <br />
            <br />
            <div className="col-md-6 ">
              {/* <img className="img-fluid rounded-3" src={about3} alt="" /> */}
            </div>
            <br />
            <div className="col-md-6 ">
              <div className="container" style={{ height: "10vh" }}></div>
              <div
                className="title mx-4"
                style={{ fontFamily: "DM Sans" , fontSize: "5vh" , color: "#05445E" , fontWeight: "500" }}
              >
                Objectives
              </div>

              <div className=" " style={{lineHeight: "2"}}>
                <ul>
                  <li>Enable data capture in varying condition. </li>
                  <li>
                    Design generative algorithms for synthetic data generation.{" "}
                  </li>
                  <li>Design / explore tools for data annotation. </li>
                  <li>Automation of data curation. </li>
                  <li>Data archival and organization. </li>
                  <li>
                    Design platform / pipeline for data visualization and
                    presentation.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "" }}>
            
            <div
              className="title mx-4 "
              style={{ fontFamily: "DM Sans" , color: "#05445E" , fontSize: "5vh" , fontWeight: "500"}}
            >
              Facilities
            </div>
            <br />
            <div className="row p-0 gy-5" style={{ paddingBlock: "2em" }}>
              <div className="col-md  d-flex justify-content-center">
                <div className="card border-0 " style={{ backgroundColor: "" }}>
                  <img
                    // src={seed1}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                  <div className="card-body d-flex justify-content-center">
                    <p className="card-text">Image Capture</p>
                  </div>
                </div>
              </div>
              <div className="col-md d-flex justify-content-center">
                <div
                  className="card border-0   "
                  // style={{ width: "30vh" }}
                  style={{ backgroundColor: "" }}
                >
                  {/* <img src={seed2} className="card-img-top" alt="..." /> */}
                  <div className="card-body d-flex justify-content-center">
                    <p className="card-text">Zoom Capture</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 d-flex justify-content-center">
                <div
                  className="card border-0  "
                  // style={{ width: "30vh" }}
                  style={{ backgroundColor: "" }}
                >
                  {/* <img src={seed3} className="card-img-top" alt="..." /> */}
                  <div className="card-body d-flex justify-content-center">
                    <p className="card-text">3D Data Capture</p>
                  </div>
                </div>
              </div>
              <div className="col-md d-flex justify-content-center">
                <div
                  className="card border-0   "
                  // style={{ width: "30vh" }}
                  style={{ backgroundColor: "" }}
                >
                  {/* <img src={seed4} className="card-img-top" alt="..." /> */}
                  <div className="card-body d-flex justify-content-center">
                    <p className="card-text">AR / VR Setup</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2 d-flex justify-content-center">
              <div
                className="card border-0  mb-3  p-3"
                // style={{ width: "30vh" }}
                style={{ backgroundColor: "whitesmoke" }}
              >
                <img src={seed5} className="card-img-top" alt="..." />
                <div className="card-body d-flex justify-content-center">
                  <p className="card-text">Server & Storage</p>
                </div>
              </div>
            </div> */}
              <div className="col-md d-flex justify-content-center">
                <div
                  className="card border-0  "
                  // style={{ width: "30vh" }}
                  style={{ backgroundColor: "" }}
                >
                  {/* <img src={seed6} className="card-img-top" alt="..." /> */}
                  <div className="card-body d-flex justify-content-center">
                    <p className="card-text">Computing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="title fw-bold fs-2 mx-4">
            Research
        </div>
        <br />
        <div className="">
            
        </div> */}
        </div>
        <Footer_Trial />
      </div>
    </>
  );
}

export default Seed_Trial;
