import React from "react";
import "../../Stylesheets/Research_Cards.css";

function Cards(props) {
  return (
    <>
      <div className="m-3">
        {/* <div className="fw-bold fs-5"></div> */}
        <div className="card h-100 research-cards border-0 rounded-0">
            {/* style={{
              maxHeight: "500px"}}> */}
          <img
            src={props.img}
            loading="lazy"
            className="card-img-top rounded-0 img-fluid"
            alt="..."
          />
          <div className="card-body p-4">
            <div className="card-title ">
              <h3
                className=""
                style={{
                  fontSize: "2.8vh",
                  fontFamily: "Inter",
                  // fontFamily: "DM Sans",
                  fontWeight: "500",
                  color: "#05445E",
                  textAlign:"left"
                }}>
                {props.title}
              </h3>
            </div>
            <p className="card-text mt-4" style={{ fontFamily: "Inter" }}>
              {props.desc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
