import React, { useContext, useEffect, useState} from "react";
import Navbar from "../Components/Common/Navbar";
// import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
// import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
// import pub_background from "../Images/Publications/pub_background.webp";
// import Year_filter from "../Components/Publications/Year_filter";
// import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
// import { Link } from "react-router-dom";

import Breadcrumbs from "../Components/Common/Breadcrumbs";


function Dataset_RSUIGM() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  // let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    // loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    // loading = false;
  }, [category, year, paperType]);
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
    <BackToTop /> 
    <Navbar/>
        <br />
        <br />
        <br />
        
        <div
          className="heading"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start text-light  align-items-center"
              style={{
                fontWeight: 500,
                // fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
              RSUIGM Dataset
            </div>
            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
            
          </div>
        </div>
        
        <br />
        <div className="container">
        <Breadcrumbs/>
    <div className="row">
    <div className="container">
    <p>Click the link below to download the dataset: 
    <a href="https://drive.google.com/drive/folders/1suy4UEUW1T3r39ajzcx8k_0pYvFxFnZ1?usp=share_link" download>RSUIGM Dataset</a>

    </p>
    </div>
    </div>
    <div className="row">
    {/* <img src="your-image.webp" class="img-fluid" alt="Your Image"/> */}
   
    </div>

</div>
        {/* <div className="container">
          <div className="row" style={{ paddingBlock: "2em" }}>
            <div
              className="col-md d-flex justify-content-center align-items-center p-0"
              style={{
                backgroundColor: "",
                overflowY: "visible",
                minHeight: "20vh",
              }}
            >
              <p
                className=""
                style={{
                  fontSize: "5vh",
                  fontFamily: "Inter",
                  color: "#05445E",
                  fontWeight: "500",
                }}
              >
                Who we are, <br /> What we do, <br /> Why we do it
              </p>
            </div>
            <div
              className="col d-flex align-items-center p-0"
              style={{ textAlign: "left" , lineHeight: "2" }}
            >
              <p>
                Center of Excellence in Visual Intelligence (CEVI) earlier known
                as Computer Vision and Graphics (CVG) lab is a research center
                with the focus towards creating a renowned, state-of-the-art
                facility equipped to create innovative visual intelligence
                solutions. The CEVI has research footprints in the area of
                artificial intelligence and data engineering through its
                collaborations, funded projects since 2009. CEVI conducts summer
                and winter workshops (Visual intelligence workshop) every year,
                catering 500 plus students and faculty across institutes. The
                faculty and students at CEVI have published papers in renowned
                journals and conferences like TPAMI, MVA, CGJ, JNN, CVPR, ICCV,
                SIGGRAPH, SIGGRAPH ASIA, NeurIPS, ICPR, ICDAR, ACCV, BMVC.
              </p>
            </div>
          </div>
          </div> */}

            <br/>
            <br/>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        <Footer_Trial />

          </div>
      </>
  );
}

export default Dataset_RSUIGM;
