import React from "react";
import "../../Stylesheets/Research_Cards.css";
import { Link } from "react-router-dom";

function Cards(props) {
  return (
    <>
      <div
        className="card  h-100 research-cards border-0"
        style={{
          color: "#3d5066",
          textAlign: "center",
          marginInline: "1em",
          maxWidth: "100%",
          backgroundColor: "",
          // maxHeight: "80%" 
        }}
      >
        <img
          src={props.img}
          className="  bg-white card-img-top rounded-0 img-fluid"
          alt="..."
          style={{ width: "100%" }}
        />

        <div className="card-body justify-content-start">
          <div
            className="card-title "
            style={{
              fontSize: "3vh",
              fontFamily: "Inter",
              fontWeight: "500",
              textAlign: "left",
              height: "100%",
              color: "#05445E",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={props.link}
              className="stretched-link"
            >
              {props.title}
            </Link>
          </div>
          <p
            className="card-text mt-1 "
            style={{
              fontFamily: "Inter",
              lineHeight: "1.7",
              textAlign: "justify",
            }}
          >
            {props.desc}
          </p>
        </div>
      </div>
    </>
  );
}

export default Cards;
