// import React, { useContext } from "react";
// import paperTypeContext from "../../Global varaibles/PaperType_filter/PaperType_context";

// function Paperfilter() {
//   const context = useContext(paperTypeContext);
//   const types = ["Journal", "Conference"];
//   const { setPaperType } = context;
//   return (
//     <>
//       <li>
//         {/* eslint-disable jsx-a11y/anchor-is-valid */}
//         <a
//           className="dropdown-item"
//           href="#"
//           onClick={() => {
//             setPaperType("");
//           }}
//         >
//           All
//         </a>
//         {/* eslint-enable jsx-a11y/anchor-is-valid */}
//       </li>
//       {types.map((e, index) => {
//         return (
//           <li key={index}>
//              {/* eslint-enable jsx-a11y/anchor-is-valid */}
//             <a
//               className="dropdown-item"
//               href="#"
//               onClick={() => {
//                 setPaperType(e);
//               }}
//             >
//               {e}
//             </a>
//              {/* eslint-enable jsx-a11y/anchor-is-valid */}
//           </li>
//         );
//       })}
//     </>
//   );
// }

// export default Paperfilter;

import React, { useContext } from "react";
import paperTypeContext from "../../Global varaibles/PaperType_filter/PaperType_context";

function PaperFilter() {
  const context = useContext(paperTypeContext);
  const types = ["Journal", "Conference"];
  const { setPaperType } = context;

  return (
    <>
      <li>
        <button
          className="dropdown-item"
          onClick={() => {
            setPaperType("");
          }}
        >
          All
        </button>
      </li>

      {types.map((e, index) => (
        <li key={index}>
          <button
            className="dropdown-item"
            onClick={() => {
              setPaperType(e);
            }}
          >
            {e}
          </button>
        </li>
      ))}
    </>
  );
}

export default PaperFilter;
