import React, { useState } from "react";
import CategoryContext from "./Category_context";

function Category(props) {
  const [category, setCategory] = useState("");
  return (
    <>
      <CategoryContext.Provider value={{ category, setCategory }}>
        {props.children}
      </CategoryContext.Provider>
    </>
  );
}

export default Category;
