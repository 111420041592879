// import React from "react";
import React, { useRef, useEffect  } from "react";
import Cards from "../Components/Project_pages/Cards";
import BackToTop from "../Components/Common/BackToTop";
import Navbar from "../Components/Common/Navbar";
import Footer_Trial from "../Components/Common/Footer_Trial";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";

function Projects_main_page(props) {
  return (
    <>
      <div
        className="main pages_background d-flex flex-column min-vh-100 w-100"
        style={{
          fontFamily: "Inter",
          backgroundColor: "whitesmoke",
        }}
      >
        <BackToTop />

        <Navbar />
        <br />
        <br />
        <br />

        <div
          className="hero-research"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${props.hero}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="d-flex justify-content-start align-items-center text-light"
              style={{
                fontWeight: 500,
                fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
              {props.main}
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <div className="">
          <div
            className="container d-flex justify-content-center align-items-center"
            style={{ paddingBlock: "2em" }}
          >
            <div className="">
              {props.desc1}
              {props.desc2}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "", paddingBlock: "2em" }}>
          <div className="container ">
            <div className="row gy-5">
              {props.cardtitle1 && (
                <div className="col-md p-0 d-flex justify-content-center">
                  <Cards
                    id="01"
                    img={props.cardimg1}
                    title={props.cardtitle1}
                    desc={props.carddesc1}
                    link={props.link1}
                  />
                </div>
              )}
              {props.cardtitle2 && (
                <div className="col-md p-0 d-flex justify-content-center">
                  <Cards
                    id="02"
                    img={props.cardimg2}
                    title={props.cardtitle2}
                    desc={props.carddesc2}
                    link={props.link2}
                  />
                </div>
              )}
              {props.cardtitle3 && (
                <div className="col-md p-0 d-flex justify-content-center">
                  <Cards
                    id="03"
                    img={props.cardimg3}
                    title={props.cardtitle3}
                    desc={props.carddesc3}
                    link={props.link3}
                  />
                </div>
              )}
            </div>

            <br />
          </div>
        </div>
        <br />
        <br />
        <div style={{ backgroundColor: "" }}>
          <div className="container ">
            <div className="row gy-5">
              {props.cardtitle4 && (
                <div className="col-md d-flex justify-content-center">
                  <Cards
                    id="05"
                    img={props.cardimg4}
                    title={props.cardtitle4}
                    desc={props.carddesc4}
                    link={props.link4}
                  />
                </div>
              )}
              {props.cardtitle5 && (
                <div className="col-md d-flex justify-content-center">
                  <Cards
                    id="06"
                    img={props.cardimg5}
                    title={props.cardtitle5}
                    desc={props.carddesc5}
                    link={props.link5}
                  />
                </div>
              )}
              {props.cardtitle6 && (
                <div className="col-md d-flex justify-content-center">
                  <Cards
                    id="07"
                    img={props.cardimg6}
                    title={props.cardtitle6}
                    desc={props.carddesc6}
                    link={props.link6}
                  />
                </div>
              )}
            </div>
          </div>
          <br />

          <br />
          <br />
        </div>

        <Footer_Trial />
      </div>
    </>
  );
}

export default Projects_main_page;
