// import React from "react";
import React, { useRef, useEffect  } from "react";
import Cards from "../Components/Research_pages/Cards";
import { Link } from "react-router-dom";
import Horizontal_card from "../Components/Research_pages/Horizontal_card";
import BackToTop from "../Components/Common/BackToTop";
import Navbar from "../Components/Common/Navbar";
import Footer_Trial from "../Components/Common/Footer_Trial";
import { TypeAnimation } from "react-type-animation";

import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Research_pages(props) {

 
      
  return (
    <>
      
      <div
        className="main pages_background  min-vh-100 w-100"
        style={{
          fontFamily: "Inter",
          backgroundColor: "whitesmoke",
        }}
      >
        <BackToTop />

        <Navbar />
        <br />
        <br />
        <br />
        <div
          className="hero-research"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${props.hero}')`,
          }}
        >
          <div className="container">
            <div className="container" style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start align-items-center text-light"
              style={{
                fontWeight: 500,
                // fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
              {props.main}
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", // Initially hide for small screens
                }}
                className="d-md-block" // Display for medium and larger screens
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
        <div className="">
        
          <div
            className="container"
            style={{ paddingBlock: "2em" }}
          ><Breadcrumbs />
          
            <div className="">
              {props.desc1}
              {props.desc2}
            </div>
          </div>
        </div>
        
        <div style={{ backgroundColor: "", paddingBlock: "2em" }}>
          <div className="container">
            <div className="row gy-5">
            <div className="col-lg-4 col-md-6 p-0 d-flex justify-content-center">
              {/* <div className="col-md-4 p-0 d-flex justify-content-center"> */}
              <Link 
                to={props.link1}
                style={{ textDecoration: "none", color: "inherit" }}
                className="link">
              <Cards
                  // Links id="01"
                  id="01"
                  img={props.cardimg1}
                  title={props.cardtitle1}
                  desc={props.carddesc1}
                />
              </Link>
              </div>
              <div className="col-lg-4 col-md-6 p-0 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link2}
              className="link">
                <Cards
                  id="02"
                  img={props.cardimg2}
                  title={props.cardtitle2}
                  desc={props.carddesc2}
                /></Link>
              </div>
              <div className="col-lg-4 col-md-6 p-0 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link3}
              className="link">
                <Cards
                  id="03"
                  img={props.cardimg3}
                  title={props.cardtitle3}
                  desc={props.carddesc3}
                /></Link>
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link4}
              className="link">
                <Cards
                  id="05"
                  img={props.cardimg5}
                  title={props.cardtitle5}
                  desc={props.carddesc5}
                /></Link>
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link5}
              className="link">
                <Cards
                  id="06"
                  img={props.cardimg6}
                  title={props.cardtitle6}
                  desc={props.carddesc6}
                /></Link>
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-center">
              <Link 
                style={{ textDecoration: "none", color: "inherit" }}
                to={props.link6}
                className="link">
                <Cards
                  id="07"
                  img={props.cardimg7}
                  title={props.cardtitle7}
                  desc={props.carddesc7}
                />
                </Link>
              </div>
            </div>

            <br />
          </div>
        </div>
        <br />
        <br />

        {/* <div className="container">
          <div className="row d-flex justify-content-center">
            <Horizontal_card
              id="04"
              img={props.cardimg4}
              title={props.cardtitle4}
              desc={props.carddesc4}
            />
          </div>
        </div>
        <br />
        <br />
        <br /> */}
        {/* <div style={{ backgroundColor: "" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link4}
              className="stretched-link">
                <Cards
                  id="05"
                  img={props.cardimg5}
                  title={props.cardtitle5}
                  desc={props.carddesc5}
                /></Link>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link5}
              className="stretched-link">
                <Cards
                  id="06"
                  img={props.cardimg6}
                  title={props.cardtitle6}
                  desc={props.carddesc6}
                /></Link>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
              <Link style={{ textDecoration: "none", color: "inherit" }}
              to={props.link6}
              className="stretched-link">
                <Cards
                  id="07"
                  img={props.cardimg7}
                  title={props.cardtitle7}
                  desc={props.carddesc7}
                /></Link>
              </div>
            </div>
          </div> */}
          <br />

          <br />
          <br />
        </div>
        <div>
        <Footer_Trial />
      </div>
    </>
  );
}

export default Research_pages;
