import React from "react";
import { Link } from "react-router-dom";

function Cards_main(props) {
  return (
    <>
      <div
        className="card h-100 research-cards border-0 "
        style={{
          color: "#3d5066",
          marginInline: "1em",
          maxWidth: "100%",
          backgroundColor: "",
        }}
      >
        <img
          src={props.img}
          className=" bg-white card-img-top rounded-0 img-fluid"
          alt="..."
        />
        <div className="card-body p-0">
          <div
            className="card-title"
            style={{
              fontSize: "3vh",
              fontFamily: "Inter",
              fontWeight: "500",
              textAlign: "left",
              marginTop: "1em",

              color: "#05445E",
            }}
          >
            <Link
              to={props.link}
              className="stretched-link"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              {props.title}
            </Link>
          </div>
          {/* <h5 class="card-title ">
            <h3 className="" style={{ fontFamily: "DM Sans" }}>
              <Link
                className="fs-5"
                to={props.link}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {props.title}
              </Link>
            </h3>
          </h5> */}
          <p
            className="card-text "
            style={{ fontFamily: "Inter", textAlign: "left" }}
          >
            {props.desc}
          </p>
        </div>
      </div>
    </>
  );
}

export default Cards_main;
