import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Common/Navbar";
// import { TypeAnimation } from "react-type-animation";
import about_background from "../Images/About_Us/about-background.webp";
import img1 from "../Images/2D/Categorization.webp";
import img2 from "../Images/2D/Filtering.webp";
import $ from "jquery";
import "../Stylesheets/Publications.css";
import jsPDF from "jspdf";
import BackToTop from "../Components/Common/BackToTop";
// import ExcelDatabaseReader from "../Components/Publications/Category_filter";
import placeholder from "../Images/Publications/DA-AE.webp";
import categoryContext from "../Global varaibles/Category_filter/Category_context";
import Footer_Trial from "../Components/Common/Footer_Trial";
// import pub_background from "../Images/Publications/pub_background.webp";
// import Year_filter from "../Components/Publications/Year_filter";
// import Paperfilter from "../Components/Publications/Paperfilter";
import yearContext from "../Global varaibles/Year_filter/Year_context";
import paperTypeContext from "../Global varaibles/PaperType_filter/PaperType_context";
// import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Two_Dimensional_Page4() {
  const context = useContext(categoryContext);
  const yearcontext = useContext(yearContext);
  const typecontext = useContext(paperTypeContext);
  const { year } = yearcontext;
  let loading = true;
  let count = 0;
  const { category } = context;
  const { paperType } = typecontext;
  const jQueryCode = () => {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        // var value = "DP"

        $(".myTable").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
      });
    });

    $("#pdf_download").click(function () {
      // Landscape export, 2×4 inches
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [4, 2],
      });

      var source = window.document.getElementsByTagName("div")[14];
      doc.html(source);
      doc.save("two-by-four.pdf");
    });
  };
  const [data, setData] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");
    const json = await response.json();

    json.shift();
    setData(json);
  };

  useEffect(() => {
    loading = false;
    jQueryCode();
    loadExcel();
  }, []);

  useEffect(() => {
    loading = false;
  }, [category, year, paperType]);
  return (
    <>
    <div className="min-vh-100" style={{ fontFamily: "Inter" }}>
        <BackToTop /> 
        <Navbar/>
        <br />
        <br />
        <br />
        <div
          className=""
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${about_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className="heading d-flex justify-content-start text-light  align-items-center flex wrap"
              style={{
                fontWeight: 500,
                // fontSize: "5vh",
                fontFamily: "Inter",
              }}
            >
               <span className="truncateText">
              Filtering, Classification, and Categorization of 2D Data
              </span>
            </div>
            {/* <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                }}
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div> */}
          </div>
        </div>
        <br />
        <div className="container">
        <Breadcrumbs/>
          <div className="row">
          <div className="container">
          
                  Our research initiatives encompass the development of advanced methodologies for the categorization of images. We focus on creating algorithms and models that can efficiently classify images into distinct categories or classes based on their content, characteristics, and features. This process involves the extraction of discriminative features and the utilization of machine learning and deep learning techniques to enable precise image categorization. The ability to categorize images is fundamental in a wide array of applications, including image retrieval, content organization, and object recognition in computer vision. By refining these categorization algorithms, we strive to provide enhanced capabilities for automatically organizing and understanding visual data, ultimately contributing to more efficient and informed decision-making processes in various fields, such as categorization towards 3D reconstruction, e-commerce, healthcare, and multimedia content management.
            </div>
          </div>
          <br/>
          <div className="col text-center">
                  <img src={img1} className="img-fluid" alt="..."/>
            
          </div>
          <br/>
          <div className="row">
          <div className="container">
                  Our research endeavors extend to the development of sophisticated techniques for filtering distorted images. This involves the creation of algorithms and models that can effectively mitigate various forms of distortions in visual data. These distortions may encompass noise, blurriness, and other imperfections that can compromise image quality. The application of such filtering methods is vital across numerous domains, including medical imaging, satellite imagery processing, 3D reconstruction, and content restoration. By designing and implementing robust filtering algorithms, we aim to enhance the quality and clarity of distorted images, making them more reliable and informative for downstream analysis and decision-making processes.
            </div>
          </div>
          <br/>
          <div className="col text-center">
                  <img src={img2} className="img-fluid" alt="..." />
            
          </div>
          <br/>
          
        </div>
                {/* related publications code */}
                <br/>
        <div
              className="title"
              style={{
                textAlign: "center",
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
              }}
            >
              Related Publications
            </div>
        <div className="container">
              {data &&
                data.map((t, index) => {
                  if (
                    t[0] &&
                    t[3] &&
                    t[6] &&
                    t[9] &&
                    t[3].toString().includes(year) &&
                    t[9].includes(category) &&
                    t[6].includes(paperType) &&
                    t[1] != "Paper Title"
                  ) {
                    count += 1;
                    let image = placeholder;
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    try {
                      image = require(`../publication_thumbnails/${t[13]}.webp`);
                    } catch {}
                    let arr = t[9].split(", ");
                    console.log(arr)
                    for (let i = 0; i < arr.length; i++){
                    if(arr[i] == "Filtering Classification and Categorization of Data"){
                    return (
                      <div key={index} className="myTable mb-0">
                        <div className="pub-cards  card border border-0 mb-4  rounded-0  ">
                          <div className="row">
                            <div className="col-md-3 d-flex align-items-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                              <img
                                src={image}
                                className="img-fluid rounded-0 align-items-center"
                                alt="..."
                            style={{ maxHeight: "100%", maxWidth: "100%" }}

                              />{" "}
                            </div>

                            <div className="col-md">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{
                                    fontFamily: "Inter",
                                    color: "#05445E",
                                    fontSize: "1.2em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t[1]}
                                </h5>

                                <p
                                  className="card-subtitle mb-0"
                                  style={{
                                    fontFamily: "Inter",
                                    lineHeight: "1.7",
                                    // fontSize: "16px",
                                  }}
                                >
                                  {t[4]}
                                </p>
                                <div className="card-text mb-2">
                                  <small
                                    className="text-body-secondary"
                                    style={{
                                      fontFamily: "Inter",
                                      lineHeight: "1.7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t[7]}, {t[2]} - {t[3]}
                                  </small>
                                </div>
                                <div className="" style={{}}>
                                  <a
                                    href={`${t[8]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [PDF]
                                  </a>
                                  <a
                                    href={`${t[12]}`}
                                    style={{
                                      padding: "0.5em",
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Code]
                                  </a>
                                  <a
                                    href={`${t[11]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#05445E",
                                    }}
                                  >
                                    [Video]
                                  </a>
                                </div>

                              
                                <p className="visually-hidden">Type: {t[6]}</p>
                                <p className="visually-hidden">
                                  Topics: {t[9]}
                                </p>
                                <p className="visually-hidden">All</p>
                              </div>
                            </div>

                           </div>
                        </div>
                      </div>
                    );}}
                  }
                })}

              {count ? (
                <></>
              ) : (
                <div className="fs-1 d-flex justify-content-center">
                  No results found.
                </div>
              )}
            </div>
            {/* related publications code */}
        <Footer_Trial />
      </div>
      </>
  );
}

export default Two_Dimensional_Page4;
