import React, { useContext, useEffect, useState } from "react";
import categoryContext from "../../Global varaibles/Category_filter/Category_context";

function ExcelDatabaseReader() {
  const context = useContext(categoryContext);
  const { setCategory } = context;
  const handleClick = (value) => {
    setCategory(value);
  };
  const [topics, setTopics] = useState([]);
  const loadExcel = async () => {
    const response = await fetch("https://cevi.co.in/publication_react.php");

    const json = await response.json();

    json.shift(); // Remove the first element (unset($sheetData[0]))

    let str = "";
    for (let i = 0; i < json.length; i++) {
      const t = json[i];

      if (t[0] === undefined || !t[0] || t[9] === "null") {
        break;
      }
      str = str + t[9] + ",";
    }

    const topics = [...new Set(str.split(","))]; // Equivalent to array_unique(explode(',', $str))

    setTopics(topics);
  };

  useEffect(() => {
    loadExcel();
  }, []);

  return (
    <>
      <li>
        <a
          className="dropdown-item"
          onClick={() => {
            handleClick("");
          }}
          href="#"
        >
          All
        </a>
      </li>
      {topics.map((t, index) => {
        if (t !== "null" && t != "" && t !== " ")
          return (
            <li key={index}>
              <a
                onClick={() => {
                  handleClick(t);
                }}
                className="dropdown-item"
                href="#"
              >
                {t}
              </a>
            </li>
          );
      })}
    </>
  );
}

export default ExcelDatabaseReader;
