
import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Common/Navbar";
import Projects_main_page from "../Page_Layouts/Projects_main_page";
import sponsored1 from "../Images/Sponsored_Project/AICTE.webp";
import sponsored2 from "../Images/Sponsored_Project/hampi.webp";
import Cards_main from "../Components/Research/Cards_main";
import sponsored3 from "../Images/Sponsored_Project/Histopathology.webp";
import dp from "../Images/Sponsored_Project/underwater.webp";
import multi from "../Images/Research/multimodal_gif.webp";
import ar from "../Images/Research/AR.webp";
import research_background from "../Images/About_Us/about-background.webp";
import { TypeAnimation } from "react-type-animation";
import Footer_Trial from "../Components/Common/Footer_Trial";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Components/Common/Breadcrumbs";

function Sponsored_Research() {
  return (
    
    <>
    
      {/* <Projects_main_page
        hero={projects_background}
        main="Sponsored Research Projects"

        

        // cardtitle1="AICTE-RPS-2019-2023"
        // cardimg1={sponsored1}
        // // cardtitle2="DST_IDH 2011-2015"
        // cardtitle3="DST_IHDS-Crowdsourcing 2019-2024"
        // cardimg3={sponsored2}
        // cardtitle4="VGST_V-Vaidya 2021-2023"
        // cardimg4={sponsored3}
        // cardtitle5="DST-DP 2019-24"
        // cardimg5={dp}
        // link1="/projects/sponsored/aicte_rps"
        // link3="/projects/sponsored/ihds"
        // link4="/projects/sponsored/vaidya"
        // link5="/projects/sponsored/dst-dp"
      /> */}
      <>
      <div className="min-vh-100" style={{ backgroundColor: "white" }}>
        <Navbar />
        <br />
        <br />
        <br />


        <div
          className="hero-research1"
          id=""
          style={{
            minHeight: "30vh",
            backgroundImage: `url('${research_background}')`,
          }}
        >
          <div className="container">
            <div className="container " style={{ height: "10vh" }}></div>
            <div
              className=" heading d-flex justify-content-start align-items-center text-light"
              style={{
                fontWeight: 500,
                fontFamily: "Inter",
                // fontSize: "5vh",
              }}
            >
              Sponsored Research Projects
            </div>
            <div style={{}}>
              <TypeAnimation
                sequence={[
                  "#Empowering Innovation through Visual Perception",
                  500,
                  "#Redefining Possibilities with Visual Intelligence",
                  500,
                  "#Advancing Visual Intelligence through Innovation",
                  500,
                  "#Where pixels inspires brilliance",
                  500,
                ]}
                style={{
                  fontSize: "1.352rem",
                  fontFamily: "Inter",
                  color: "white",
                  display: "none", // Initially hide for small screens
                }}
                className="d-md-block" // Display for medium and larger screens
                omitDeletionAnimation={true}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
     
        <br />
       

        <div className="research-content1">
          <div className="container">
          <Breadcrumbs />
          <div
              className="title"
              style={{
                fontSize: "4vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                textAlign:"center",
              }}
            >
              Ongoing Projects
            </div>

            <div className="row">

              {/* <div className="col-md p-0"> */}
              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={sponsored2}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/draganddropsnapcopy-6e153bc203561a018c36a2cc113cba28/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="DST IHDS-Crowdsourcing 2019-2024"
                  id="02"
                  link="/projects/sponsored/ihds"
                  desc=""
                />
              </div>

              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={dp}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="DST Digital Poompuhar 2019-24"
                  id="03"
                  link="/projects/sponsored/dst-dp"
                  desc=""
                />
              </div>
              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={sponsored3}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="VGST V-Vaidya 2021-2023"
                  id="03"
                  link="/projects/sponsored/vaidya"
                  desc=""
                />
              </div>

              <div className="col-lg col-md-6 p-0 mb-5">
                <Cards_main
                  img={sponsored1}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/clonergridmotioncopy-3133ca9854ef1ed4aff5f1c854c7b93e/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="AICTE-RPS Shape Representation 2019-2023"
                  id="01"
                  link="/projects/sponsored/aicte_rps"
                  desc=""
                />
              </div>

              {/* <div className="col-md" style={{ marginInlineEnd: "1em" }}>
                <Cards
                  img={research3}
                  // iframe={
                  //   <iframe
                  //     src="https://my.spline.design/purple3diconscopy-7b63e4e2044942a5b813f3bea65b95ac/"
                  //     frameborder="0"
                  //     width="100%"
                  //     height="100%"
                  //   ></iframe>
                  // }
                  title="Presentation through AR / VR / XR"
                  id="03"
                  link="/research/multimodal"
                  desc="At our research center, we specialize in the exciting field of multimodal acquisition and processing."
                />
              </div> */}
            </div>

            <div
              className="title"
              style={{
                // fontSize: "5vh",
                paddingBlockEnd: "1em",
                fontFamily: "Inter",
                color: "#05445E",
                textAlign:"center",
              }}
            >
              Completed Projects
            </div>
            <div className="row">
            <div className="container">
              <ol>
                <li>  
                <b> DST: India Digital heritage ‘Acquisition, representation and rendering of digital heritage sites’ in collaboration with IIT Delhi’  (2010-2015) </b>
                <br/>
                The main objective is to create algorithms and techniques to acquire three-dimensional digital replica of Indian digital heritage sites.  We have developed a framework for coarse to fine 3D reconstruction using single-view reconstruction and, fine reconstruction using depth sensor (Kinect) and Multiview 3D reconstruction. 

                </li>
                <li>
                <b>DST: India Digital heritage ‘Gesture based virtual walkthrough of digital heritage sites – Hampi’ (2011-2015)</b>
                <br/>
                We have proposed a number of 3D point cloud representations and processing methods for the We are also exploring how missing and occluded areas as well as areas that produce significant noise in the captured data are effectively interpolated.
                      
                </li>
                <li> 

                <b>IEEE EPIC scheme:  Communicate ‘A Hand Speech Glove (2010-2018)'</b>
                <br/>
                Objective is to develop a human interface device that converts the mechanism of hand sign language into alphanumerical characters which would assist the user to communicate via text display and speech. Awards: (i) Won first prize at India, Innovation Initiative-i3 National fair 2012, held at IIT Delhi, 3 Dec 2012. Received a cash prize of Rs. 25,000/ and represented India in the commonwealth nations meet during Jan 2013. (iii) Infymakers Award, (iv) IESA award.
                
                </li>
                <li> 
                
                <b>Naval Research Board (NRB):  ‘Super-resolution and retargeting in Images and Videos’ (2009-2012) in collaboration with IIT Delhi and IIIT Hyderabad</b> 
                <br/>
                The aim is to generate a summary of the video by retaining salient activities in a given time. We propose a method for selection of salient activities using motion of feature points as a key parameter, where the saliency of a frame depends on total motion and specified time for summarization. The motion information in a video is modelled as a Gaussian mixture model (GMM), to estimate the key motion frames in the video. The salient frames are detected depending upon the motion strength of the keyframe and user specified time, which contributes for the summarization keeping the chronology of activities. 
                </li>
                <li>
                
                <b>DRDO, ADE- ‘Robust horizon  detection for UAV’ (2013 - 2014)</b>
                <br/>
                We have proposed number of horizon detection methods and also method to select robust methods for a given scenario for the robust horizon detection for UAVs.  
                
                </li>
                <li>
                
                <b>VTU: ‘Image Fusion and Quality metrics for images’ (2010-2014)</b>
                <br/>
                The main objective is to develop framework which provides better fusion results for different input images. We provide a new quality to quantify the fused image. 
              
              </li>
              </ol>
            </div>
            </div>


          </div>
        </div>
        {/* <br /> */}
        <br />
        <Footer_Trial />
      </div>
    </>
    </>
  );
}

export default Sponsored_Research;
