import React from "react";
import PublicationsImage1 from "../../Images/Publications/DA-AE.webp"

function Horizontal_Card(props) {
  return (
    <>
      <div
        className="card rounded-0 border-0 border-bottom "
        style={{ fontFamily: "Inter" }}
      >
        <div className="row g-0 gx-4" style={{ justifyContent: "center" }}>
        <div className="col-lg-3 col-md-10 justify-content-center align-items-start d-none d-sm-none d-md-block">
            <div className="align-items-center">
              <div>
                <img src={props.imageSrc} className="img-fluid" alt="Publications image" style={{width: "100%" , paddingTop: "em" }} />
              </div>
            </div>
          </div>

          <div className="col-lg">
            <div className="card-body">
              <h5
                className="card-title1"
                // style={{ color: "#05445E", fontSize: "3vh" }}
                style={{ color: "#05445E"}}
              >
                {props.title}
              </h5>
              <p className="card-text mb-0">{props.author}</p>
              <p className="card-text">
                <small className="text-body-secondary mb-0">{props.time}</small>
              </p>
              <a
                href={props.pdfLink} 
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#05445E",
                }}
              >
                [PDF]
              </a>
              <a
                href={props.codeLink}
                target="_blank"
                rel="noopener noreferrer" 
                style={{
                  padding: "0.5em",
                  textDecoration: "none",
                  color: "#05445E",
                }}
              >
                [Code]
              </a>
              <a
                href={props.videoLink} 
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#05445E",
                }}
              >
                [Video]
              </a>
            </div>
          </div>
      
          {/* <div className="col-lg-3 d-flex justify-content-center align-items-center">
            
            <button className="card-button">Know More</button>
            
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Horizontal_Card;




