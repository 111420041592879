import React, { useState } from "react";
import yearContext from "./Year_context";

function Year(props) {
  const [year, setYear] = useState("");
  return (
    <>
      <yearContext.Provider value={{year , setYear}}>{props.children}</yearContext.Provider>
    </>
  );
}

export default Year;
