import React from "react";
import { Link } from "react-router-dom";
import gmail from "../../Images/Footer/email.svg"
import linkedin from "../../Images/Footer/linkedin.svg";
import github from "../../Images/Footer/github.svg";
import twitter from "../../Images/Footer/twitter.svg";
import cevi from "../../Images/Footer/cevi-logo.png";
import "../../Stylesheets/Footer.css";


function Footer_Trial() {
  return (
    <>  
      <footer
        className="footer"
        style={{ backgroundColor: "#222222", position:"relative" }}
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md gy-md-0 gy-5 d-md-flex justify-content-start">
              <img src={cevi} alt="CEVI" height="50vh" />
            </div>
            <div className="col-md gy-5 gy-md-0 text-light">
              Copyright &#169; 2023 - CEVI. Powered by KLE Tech
            </div>
            <div className="col-md gy-md-0 gy-5">
              <div className="d-flex justify-content-end">
                <Link to="https://twitter.com/kletech_cevi"  target="_blank" rel="noopener noreferrer" style={{ paddingInlineEnd: "1em" }}>
                  <img className="img-fluid" src={twitter} alt="twitter" />
                </Link>

                <Link to="https://in.linkedin.com/company/center-of-excellence-in-visual-intelligence-cevi" target="_blank" rel="noopener noreferrer" style={{ paddingInline: "1em" }}>
                  <img className="img-fluid" src={linkedin} alt="linkedin" />
                </Link>

                <Link to="mailto:cevi@kletech.ac.in"  target="_blank" rel="noopener noreferrer" style={{ paddingInline: "1em" }}>
                  <img className="img-fluid" src={gmail} alt="gmail" />
                </Link>

                <Link to="https://github.com/CeviKle" target="_blank" rel="noopener noreferrer" style={{ paddingInline: "1em"}}>
                  <img className="img-fluid" src={github} alt="github" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

        
    </>
  );
}

export default Footer_Trial;
