import React from "react";
import "../../Stylesheets/Marquee.css";
import Tooltip from "@mui/material/Tooltip";

function Marquee(props) {
  return (
    <>
      <div className="slider">
        <div className="slide-track">
          {props.imgs &&
            props.imgs.map((obj, i) => {
              return (
                <div id="box" role="group" aria-label="Image Slides" style={{ position: "relative" }} key={i}>
                  <Tooltip title={obj.text} followCursor={true}>
                    <div className="slides">
                      <img src={obj.img} className="" alt="..." />
                    </div>
                  </Tooltip>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Marquee;
